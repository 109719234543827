import React from 'react';
import {useIntl, FormattedMessage} from 'react-intl';

export default function MainMenu(props) {
	
	const [menuExpanded, setMenuExpanded] = React.useState(false);
	
	function menuButtonClick(event) {
		setMenuExpanded(!menuExpanded);
	}
	
	return (
		<>
			<nav id="navtest" className="gcweb-menu wb-init" typeof="SiteNavigationElement">
				<div className="container">
					<h2 className="wb-inv"><FormattedMessage id="wet.topMenu.menu"/></h2>
					<button type="button" aria-haspopup="true" aria-expanded={menuExpanded} onClick={(event) => menuButtonClick(event)}><FormattedMessage id="wet.topMenu.mainMenu" values={{span: chunks => <span className="wb-inv">{chunks} </span>}}/> <span className="expicon glyphicon glyphicon-chevron-down"></span></button>
					<ul role="menu" aria-orientation="vertical">
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.jobsAndTheWorkplace.link"})}><FormattedMessage id="wet.topMenu.jobsAndTheWorkplace"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.immigrationAndCitizenship.link"})}><FormattedMessage id="wet.topMenu.immigrationAndCitizenship"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.travelAndTourism.link"})}><FormattedMessage id="wet.topMenu.travelAndTourism"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.businessAndIndustry.link"})}><FormattedMessage id="wet.topMenu.businessAndIndustry"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.benefits.link"})}><FormattedMessage id="wet.topMenu.benefits"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.health.link"})}><FormattedMessage id="wet.topMenu.health"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.taxes.link"})}><FormattedMessage id="wet.topMenu.taxes"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.environmentAndNaturalResources.link"})}><FormattedMessage id="wet.topMenu.environmentAndNaturalResources"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.nationalSecurityAndDefence.link"})}><FormattedMessage id="wet.topMenu.nationalSecurityAndDefence"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.cultureHistoryAndSport.link"})}><FormattedMessage id="wet.topMenu.cultureHistoryAndSport"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.policingJusticeAndEmergencies.link"})}><FormattedMessage id="wet.topMenu.policingJusticeAndEmergencies"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.transportAndInfrastructure.link"})}><FormattedMessage id="wet.topMenu.transportAndInfrastructure"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.canadaAndTheWorld.link"})}><FormattedMessage id="wet.topMenu.canadaAndTheWorld"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.moneyAndFinances.link"})}><FormattedMessage id="wet.topMenu.moneyAndFinances"/></a></li>
						<li role="presentation"><a role="menuitem" href={useIntl().formatMessage({id:"wet.topMenu.scienceAndInnovation.link"})}><FormattedMessage id="wet.topMenu.scienceAndInnovation"/></a></li>
					</ul>
				</div>
			</nav>
		</>
	);
}

