// ! Remember, any changes here need to be reflected in the inquiry.json JSON schema

export const CommercialDataHolder = (messages) => {
	
	const modeOfTransportOptions = [
		{value: 'Air [modeOfTransport=888060000]', label: messages['CIPS.i18n-modeOfTransport-air']}, 
		{value: 'Courier [modeOfTransport=888060001]', label: messages['CIPS.i18n-modeOfTransport-courier']}, 
		{value: 'Highway [modeOfTransport=888060002]', label: messages['CIPS.i18n-modeOfTransport-highway']}, 
		{value: 'Mail [modeOfTransport=888060003]', label: messages['CIPS.i18n-modeOfTransport-mail']}, 
		{value: 'Marine [modeOfTransport=888060004]', label: messages['CIPS.i18n-modeOfTransport-marine']}, 
		{value: 'Rail [modeOfTransport=888060005]', label: messages['CIPS.i18n-modeOfTransport-rail']}
	  
	  ];

	const commercialProgramOptions   = [
		{ value: 'Canadian importer/exporter [commercialProgram=100000001]', label: messages['contact.i18n-commercialProgram-canadianImporter-Exporter'] },
		{ value: 'Carrier [commercialProgram=100000002]', label: messages['contact.i18n-commercialProgram-carrier'] },
		{ value: 'Customs broker licensing [commercialProgram=100000003]', label: messages['contact.i18n-commercialProgram-customsBrokerLicensing' ]},
		{ value: 'EDI service/software provider [commercialProgram=100000004]', label: messages['contact.i18n-commercialProgram-ediService-SoftwareProvider'] },
		{ value: 'Freight forwarder [commercialProgram=100000005]', label: messages['contact.i18n-commercialProgram-freightForwarder']},
		{ value: 'Non-resident importer/exporter [commercialProgram=100000006]', label: messages['contact.i18n-commercialProgram-non-ResidentImporter-Exporter'] },
		{ value: 'Warehouse operator [commercialProgram=100000000]', label: messages['contact.i18n-commercialProgram-sufferanceWarehouseLicensing' ]}
	];
	
	const iamOptions = [
	{ value: 'An authorized representative of the company [iam=888060000]', label: messages['contact.i18n-iam-anAuthorizedRepresentativeOfTheCompany'] },
	{ value: 'An employee of the company [iam=888060001]', label: messages['contact.i18n-iam-anEmployeeOfTheCompany'] }
	];
	
	const authRepTypeOptions = [
		{ value: 'Accountant [authRepType=888060003]', label: messages['contact.i18n-authRepType-accountant' ]},
		{ value: 'Financial Institution [authRepType=100000001]', label: messages['contact.i18n-authRepType-anFinancialInstitution'] },
		{ value: 'Foreign exporter [authRepType=888060006]', label: messages['contact.i18n-authRepType-foreignExporter'] },
		{ value: 'Foreign vendor [authRepType=888060005]', label: messages['contact.i18n-authRepType-foreignVendor']},
		{ value: 'Lawyer [authRepType=888060002]', label: messages['contact.i18n-authRepType-lawyer']},
		{ value: 'Licensed customs broker [authRepType=888060000]', label: messages['contact.i18n-authRepType-licensedCustomsBroker'] },
		{ value: 'Surety Company [authRepType=100000000]', label: messages['contact.i18n-authRepType-anSuretyCompany'] },
		{ value: 'Trade consultant [authRepType=888060001]', label: messages['contact.i18n-authRepType-tradeConsultant']},
		{ value: 'Trustee in bankruptcy [authRepType=888060004]', label: messages['contact.i18n-authRepType-trusteeInBankruptcy'] },
	];

	//Dropdown under inquiry options 2-5 to choose topic you need assistance with
    const categoryOptions = {
		cssh: [
			{ value: 'CARM Client Portal - Activate my account [category=CAT-01208]', label: messages['contact.i18n-CAT-01208'] },
			{ value: 'Access information [category=CAT-01168]', label: messages['contact.i18n-CAT-01168'] },
			{ value: 'CARM - EDI & API Support [category=CAT-01197]', label: messages['contact.i18n-CAT-01197'] },
			{ value: 'CARM Client Portal - Accounts and Profiles [category=CAT-01198]', label: messages['contact.i18n-CAT-01198'] },
			{ value: 'CARM Client Portal - Declarations [category=CAT-01199]', label: messages['contact.i18n-CAT-01199'] },
			{ value: 'CARM Client Portal - Financial Information [category=CAT-01200]', label: messages['contact.i18n-CAT-01200'] },
			{ value: 'CARM Client Portal - Navigation [category=CAT-01201]', label: messages['contact.i18n-CAT-01201'] },
			{ value: 'CARM Client Portal - Notifications [category=CAT-01202]', label: messages['contact.i18n-CAT-01202'] },
			{ value: 'CARM Client Portal - Payments [category=CAT-01204]', label: messages['contact.i18n-CAT-01204'] },
			{ value: 'CARM Client Portal - Rulings, appeals and compliance [category=CAT-01205]', label: messages['contact.i18n-CAT-01205'] },
			{ value: 'CARM Client Portal - Set-up My Portal [category=CAT-01206]', label: messages['contact.i18n-CAT-01206'] },
			{ value: 'CARM Client Portal - Upload a document [category=CAT-01207]', label: messages['contact.i18n-CAT-01207'] },
			{ value: 'CARM Client Portal - Other [category=CAT-01203]', label: messages['contact.i18n-CAT-01203'] }
		],
		tccu: [
			{ value: 'EDI & API Support [category=CAT-01005]', label: messages['contact.i18n-CAT-01005'] },
			{ value: 'Online Portal Support [category=CAT-01009]', label: messages['contact.i18n-CAT-01009'] }
		],
		cfs: [
			{ value: 'Financial account activities [category=CAT-01007]', label: messages['contact.i18n-CAT-01007'] }
		],
		bistier2: [
			{ value: 'Business registration and program enrolment [category=CAT-01003]', label: messages['contact.i18n-CAT-01003'] },
			{ value: 'Exporting commercial goods [category=CAT-01006]', label: messages['contact.i18n-CAT-01006'] },
			{ value: 'Importing commercial goods [category=CAT-01008]', label: messages['contact.i18n-CAT-01008'] },
			{ value: 'Storing commercial goods [category=CAT-01010]', label: messages['contact.i18n-CAT-01010'] },
			{ value: 'Transporting/logistics commercial goods [category=CAT-01011]', label: messages['contact.i18n-CAT-01011'] }
		],
	};

	// Another dropdown that gets populated depending on your choice from categoryOptions
	const subcategoryOptionsForCategory = {
		'CARM Client Portal - Activate my account [category=CAT-01208]': [
			{ value: 'Activate my account [subCategory=CAT-01212]', label: messages['contact.i18n-CAT-01212'] },
		],
		'Access information [category=CAT-01168]': [
			{ value: 'Help with finding information on the web site [subCategory=CAT-01169]', label: messages['contact.i18n-CAT-01169'] },
			{ value: 'I wish to sign up for CARM bulletins [subCategory=CAT-01170]', label: messages['contact.i18n-CAT-01170'] },
			{ value: 'I wish to sign up for a CARM webinar [subCategory=CAT-01171]', label: messages['contact.i18n-CAT-01171'] },
		],
		'CARM - EDI & API Support [category=CAT-01197]': [
			{ value: 'EDI Enrollment - CAD [subCategory=CAT-01250]', label: messages['contact.i18n-CAT-01250'] }, 
			{ value: 'EDI Enrollment - Statement of Account (SOA) and Daily Notices (DN) [subCategory=CAT-01251]', label: messages['contact.i18n-CAT-01251'] }, 
			{ value: 'Help with a CAD EDI transmission [subCategory=CAT-01247]', label: messages['contact.i18n-CAT-01247'] }, 
			{ value: 'Help with an API service [subCategory=CAT-01249]', label: messages['contact.i18n-CAT-01249'] }, 
			{ value: 'Help with an EDI DN/SOA [subCategory=CAT-01248]', label: messages['contact.i18n-CAT-01248'] }, 
			{ value: 'Request ECCRD - CAD [subCategory=CAT-01252]', label: messages['contact.i18n-CAT-01252'] }, 
			{ value: 'Request ECCRD - DN/SOA [subCategory=CAT-01253]', label: messages['contact.i18n-CAT-01253'] }
		],
		'CARM Client Portal - Accounts and Profiles [category=CAT-01198]': [
			{ value: 'CCP - Business profile [subCategory=CAT-01209]', label: messages['contact.i18n-CAT-01209'] }, 
			{ value: 'CCP - List of program accounts [subCategory=CAT-01211]', label: messages['contact.i18n-CAT-01211'] }, 
			{ value: 'CCP - Program account profile [subCategory=CAT-01210]', label: messages['contact.i18n-CAT-01210'] }
		],
		'CARM Client Portal - Declarations [category=CAT-01199]': [
			{ value: 'CCP - Create a declaration [subCategory=CAT-01213]', label: messages['contact.i18n-CAT-01213'] }, 
			{ value: 'CCP - Duties and taxes calculator [subCategory=CAT-01254]', label: messages['contact.i18n-CAT-01254'] }, 
			{ value: 'CCP - List of submitted declarations [subCategory=CAT-01214]', label: messages['contact.i18n-CAT-01214'] }, 
			{ value: 'CCP - Manage my templates [subCategory=CAT-01215]', label: messages['contact.i18n-CAT-01215'] }, 
			{ value: 'CCP - Request declaration report [subCategory=CAT-01218]', label: messages['contact.i18n-CAT-01218'] }, 
			{ value: 'CCP - Statements of adjustment & appeals [subCategory=CAT-01216]', label: messages['contact.i18n-CAT-01216'] }, 
			{ value: 'CCP - Summary of drawback activity [subCategory=CAT-01217]', label: messages['contact.i18n-CAT-01217'] }, 
			{ value: 'CCP - View list of requested declaration reports [subCategory=CAT-01219]', label: messages['contact.i18n-CAT-01219'] }
		],
		'CARM Client Portal - Financial Information [category=CAT-01200]': [
			{ value: 'CCP - CBSA invoices [subCategory=CAT-01222]', label: messages['contact.i18n-CAT-01222'] }, 
			{ value: 'CCP - Collection letters - Notice of Arrears [subCategory=CAT-01223]', label: messages['contact.i18n-CAT-01223'] }, 
			{ value: 'CCP - Collection letters - Notice of Lien [subCategory=CAT-01224]', label: messages['contact.i18n-CAT-01224'] }, 
			{ value: 'CCP - Financial security [subCategory=CAT-01225]', label: messages['contact.i18n-CAT-01225'] }, 
			{ value: 'CCP - Late payment interest simulation [subCategory=CAT-01226]', label: messages['contact.i18n-CAT-01226'] }, 
			{ value: 'CCP - Statement of account [subCategory=CAT-01221]', label: messages['contact.i18n-CAT-01221'] }, 
			{ value: 'CCP - Transaction history [subCategory=CAT-01220]', label: messages['contact.i18n-CAT-01220'] }
		],
		'CARM Client Portal - Navigation [category=CAT-01201]': [
			{ value: 'CCP - Logging on [subCategory=CAT-01227]', label: messages['contact.i18n-CAT-01227'] }, 
			{ value: 'CCP - Navigating [subCategory=CAT-01228]', label: messages['contact.i18n-CAT-01228'] }, 
			{ value: 'CCP - Using Filters [subCategory=CAT-01229]', label: messages['contact.i18n-CAT-01229'] }
		],
		'CARM Client Portal - Notifications [category=CAT-01202]': [
			{ value: 'CCP - Help with notifications [subCategory=CAT-01230]', label: messages['contact.i18n-CAT-01230'] }
		],
		'CARM Client Portal - Payments [category=CAT-01204]': [
			{ value: 'CCP - Allocate credit as payment [subCategory=CAT-01231]', label: messages['contact.i18n-CAT-01231'] }, 
			{ value: 'CCP - Online payment [subCategory=CAT-01234]', label: messages['contact.i18n-CAT-01234'] }, 
			{ value: 'CCP - Payment arrangement [subCategory=CAT-01233]', label: messages['contact.i18n-CAT-01233'] }, 
			{ value: 'CCP - Pre-authorized debit [subCategory=CAT-01232]', label: messages['contact.i18n-CAT-01232'] }
		],
		'CARM Client Portal - Rulings, appeals and compliance [category=CAT-01205]': [
			{ value: 'CCP - Appeals [subCategory=CAT-01235]', label: messages['contact.i18n-CAT-01235'] }, 
			{ value: 'CCP - Disenrollment and compliance [subCategory=CAT-01237]', label: messages['contact.i18n-CAT-01237'] }, 
			{ value: 'CCP - Enforcement actions [subCategory=CAT-01236]', label: messages['contact.i18n-CAT-01236'] }, 
			{ value: 'CCP - Rulings [subCategory=CAT-01255]', label: messages['contact.i18n-CAT-01255'] }
		],
		'CARM Client Portal - Set-up My Portal [category=CAT-01206]': [
			{ value: 'CCP - Account Deactivated/Locked [subCategory=CAT-01243]', label: messages['contact.i18n-CAT-01243'] }, 
			{ value: 'CCP - Enroll in a CBSA program [subCategory=CAT-01241]', label: messages['contact.i18n-CAT-01241'] }, 
			{ value: 'CCP - Manage my business relationships [subCategory=CAT-01240]', label: messages['contact.i18n-CAT-01240'] }, 
			{ value: 'CCP - Manage my employees [subCategory=CAT-01239]', label: messages['contact.i18n-CAT-01239'] }, 
			{ value: 'CCP - Personal profile [subCategory=CAT-01238]', label: messages['contact.i18n-CAT-01238'] }, 
			{ value: 'CCP - Problems with BAM [subCategory=CAT-01244]', label: messages['contact.i18n-CAT-01244'] }, 
			{ value: 'CCP - View the API key [subCategory=CAT-01242]', label: messages['contact.i18n-CAT-01242'] }
		],
		'CARM Client Portal - Upload a document [category=CAT-01207]': [
			{ value: 'CCP - Document [subCategory=CAT-01245]', label: messages['contact.i18n-CAT-01245'] }
		],
		'CARM Client Portal - Other [category=CAT-01203]': [
			{ value: 'CCP - Other [subCategory=CAT-01256]', label: messages['contact.i18n-CAT-01256'] }
		],
		'EDI & API Support [category=CAT-01005]': [
			{ value: 'Help with an ACI EDI transmission [subCategory=CAT-01031]', label: messages['contact.i18n-CAT-01031'] }, 
			{ value: 'Help with a B3 EDI transmission [subCategory=CAT-01032]', label: messages['contact.i18n-CAT-01032'] }, 
			{ value: 'Help with a G7 Export EDI transmission [subCategory=CAT-01034]', label: messages['contact.i18n-CAT-01034'] }, 
			{ value: 'Help with a Release or IID EDI transmission [subCategory=CAT-01035]', label: messages['contact.i18n-CAT-01035'] }, 
			{ value: 'Help with an RNS EDI transmission [subCategory=CAT-01036]', label: messages['contact.i18n-CAT-01036'] }, 
			{ value: 'EDI Enrolment:  ACI & Notices [subCategory=CAT-01125]', label: messages['contact.i18n-CAT-01125'] }, 
			{ value: 'Enrol in EDI participant distribution lists [subCategory=CAT-01128]', label: messages['contact.i18n-CAT-01128'] }, 
			{ value: 'EDI Enrolment: G7 Export [subCategory=CAT-01129]', label: messages['contact.i18n-CAT-01129'] }, 
			{ value: 'EDI Enrolment: IID (Single Window) [subCategory=CAT-01130]', label: messages['contact.i18n-CAT-01130'] }, 
			{ value: 'EDI Enrolment: Release [subCategory=CAT-01131]', label: messages['contact.i18n-CAT-01131'] }, 
			{ value: 'EDI Enrolment: Release Notification System [subCategory=CAT-01132]', label: messages['contact.i18n-CAT-01132'] }, 
			{ value: 'Request ECCRD - ACI & Notices [subCategory=CAT-01135]', label: messages['contact.i18n-CAT-01135'] }, 
			{ value: 'Request ECCRD - G7 Export [subCategory=CAT-01138]', label: messages['contact.i18n-CAT-01138'] }, 
			{ value: 'Request ECCRD - Release / SWI [subCategory=CAT-01139]', label: messages['contact.i18n-CAT-01139'] }, 
			{ value: 'Request ECCRD - RNS [subCategory=CAT-01140]', label: messages['contact.i18n-CAT-01140'] }
		],
		'Online Portal Support [category=CAT-01009]': [
			{ value: 'Canadian Export Reporting System Portal - Setting up my user account and preferences [subCategory=CAT-01162]', label: messages['contact.i18n-CAT-01162'] }, 
			{ value: 'Canadian Export Reporting System Portal - Activate my business account [subCategory=CAT-01086]', label: messages['contact.i18n-CAT-01086'] }, 
			{ value: 'Canadian Export Reporting System Portal - Managing my export declarations [subCategory=CAT-01160]', label: messages['contact.i18n-CAT-01160'] }, 
			{ value: 'Canadian Export Reporting System Portal - Other [subCategory=CAT-01161]', label: messages['contact.i18n-CAT-01161'] }, 
			{ value: 'eManifest Portal - Setting up my user account and preferences [subCategory=CAT-01166]', label: messages['contact.i18n-CAT-01166'] }, 
			{ value: 'eManifest Portal - Activate my business account [subCategory=CAT-01163]', label: messages['contact.i18n-CAT-01163'] }, 
			{ value: 'eManifest Portal - Manage ACI declarations [subCategory=CAT-01164]', label: messages['contact.i18n-CAT-01164'] }, 
			{ value: 'eManifest Portal - Other [subCategory=CAT-01165]', label: messages['contact.i18n-CAT-01165'] }, 
			{ value: 'Partners in Protection (PIP) portal  [subCategory=CAT-01167]', label: messages['contact.i18n-CAT-01167'] }
		],
		'Financial account activities [category=CAT-01007]': [
			{ value: 'A transaction does not belong to me [subCategory=CAT-01043]', label: messages['contact.i18n-CAT-01043'] }, 
			{ value: 'A transaction is missing [subCategory=CAT-01057]', label: messages['contact.i18n-CAT-01057'] }, 
			{ value: 'CARM Client Portal: Help with my financial account [subCategory=CAT-01044]', label: messages['contact.i18n-CAT-01044'] }, 
			{ value: 'Daily Notice/Statement - I did not receive my daily notice or statement of account (SOA) [subCategory=CAT-01051]', label: messages['contact.i18n-CAT-01051'] }, 
			{ value: 'Daily Notice/Statement - I do not understand my daily notice or statement of account (SOA) [subCategory=CAT-01052]', label: messages['contact.i18n-CAT-01052'] }, 
			{ value: 'Daily Notice/Statement - I would like to receive daily notices and statement of account [subCategory=CAT-01050]', label: messages['contact.i18n-CAT-01050'] }, 
			{ value: 'Help with reading my financial portal account [subCategory=CAT-01045]', label: messages['contact.i18n-CAT-01045'] }, 
			{ value: 'Help with understanding a transaction [subCategory=CAT-01158]', label: messages['contact.i18n-CAT-01158'] }, 
			{ value: 'How do I enrol in/change pre-authorized debit payments (PAD) [subCategory=CAT-01149]', label: messages['contact.i18n-CAT-01149'] }, 
			{ value: 'How do I make a payment [subCategory=CAT-01148]', label: messages['contact.i18n-CAT-01148'] }, 
			{ value: 'How do I post cash as security? [subCategory=CAT-01156]', label: messages['contact.i18n-CAT-01156'] }, 
			{ value: 'How much security do I need to post for the RPP? [subCategory=CAT-01155]', label: messages['contact.i18n-CAT-01155'] }, 
			{ value: 'I cannot import because my account is frozen [subCategory=CAT-01059]', label: messages['contact.i18n-CAT-01059'] }, 
			{ value: "I don't understand an offset on my account [subCategory=CAT-01061]", label: messages['contact.i18n-CAT-01061'] }, 
			{ value: "I haven't received my cheque yet [subCategory=CAT-01048]", label: messages['contact.i18n-CAT-01048'] }, 
			{ value: "I made a payment and I don't see that it was processed  [subCategory=CAT-01145]", label: messages['contact.i18n-CAT-01145'] }, 
			{ value: 'I made a payment made by cheque [subCategory=CAT-01146]', label: messages['contact.i18n-CAT-01146'] }, 
			{ value: 'I need help with a bond claim [subCategory=CAT-01265]', label: messages['contact.i18n-CAT-01265'] }, 
			{ value: 'I need help with a notice of arrears [subCategory=CAT-01058]', label: messages['contact.i18n-CAT-01058'] }, 
			{ value: 'I need to apply a payment so that I can submit a B2 request [subCategory=CAT-01147]', label: messages['contact.i18n-CAT-01147'] }, 
			{ value: 'I paid CBSA instead of CRA [subCategory=CAT-01065]', label: messages['contact.i18n-CAT-01065'] }, 
			{ value: 'I paid CRA instead of CBSA [subCategory=CAT-01067]', label: messages['contact.i18n-CAT-01067'] }, 
			{ value: 'I paid the wrong amount / duplicate payment [subCategory=CAT-01144]', label: messages['contact.i18n-CAT-01144'] }, 
			{ value: 'I received a cheque and do not know what it is for [subCategory=CAT-01046]', label: messages['contact.i18n-CAT-01046'] }, 
			{ value: 'I want to confirm receipt of a payment [subCategory=CAT-01066]', label: messages['contact.i18n-CAT-01066'] }, 
			{ value: 'I would like to apply a credit from one of my accounts to another account [subCategory=CAT-01060]', label: messages['contact.i18n-CAT-01060'] }, 
			{ value: 'I would like to enrol in the Release prior to payment program [subCategory=CAT-01154]', label: messages['contact.i18n-CAT-01154'] }, 
			{ value: 'I would like to request a refund [subCategory=CAT-01049]', label: messages['contact.i18n-CAT-01049'] }, 
			{ value: 'I would like to set up EDI payment [subCategory=CAT-01143]', label: messages['contact.i18n-CAT-01143'] }, 
			{ value: 'My opening balance is incorrect [subCategory=CAT-01142]', label: messages['contact.i18n-CAT-01142'] }, 
			{ value: 'Request ECCRD - Daily Notice /Statement of Account [subCategory=CAT-01153]', label: messages['contact.i18n-CAT-01153'] }, 
			{ value: 'Updating my address and contact information [subCategory=CAT-01047]', label: messages['contact.i18n-CAT-01047'] }, 
			{ value: 'What is release prior to payment security, how do I enrol? [subCategory=CAT-01157]', label: messages['contact.i18n-CAT-01157'] }, 
			{ value: 'Other - financial account activity [subCategory=CAT-01063]', label: messages['contact.i18n-CAT-01063'] }
		],
		'Business registration and program enrolment [category=CAT-01003]': [
			{ value: 'Carrier/Freight Forwarder program enrolment [subCategory=CAT-01013]', label: messages['contact.i18n-CAT-01013'] }, 
			{ value: 'Customs brokers licensing [subCategory=CAT-01014]', label: messages['contact.i18n-CAT-01014'] }, 
			{ value: 'How do I enrol in the Partners in Protection program [subCategory=CAT-01172]', label: messages['contact.i18n-CAT-01172'] }, 
			{ value: 'Help with exporter program enrolment [subCategory=CAT-01015]', label: messages['contact.i18n-CAT-01015'] }, 
			{ value: 'Trusted traders [subCategory=CAT-01019]', label: messages['contact.i18n-CAT-01019'] }, 
			{ value: 'Warehouse licensing [subCategory=CAT-01020]', label: messages['contact.i18n-CAT-01020'] }
		],
		'Exporting commercial goods [category=CAT-01006]': [
			{ value: 'General Guidelines for exporting goods [subCategory=CAT-01040]', label: messages['contact.i18n-CAT-01040'] }, 
			{ value: 'Trade incentives [subCategory=CAT-01074]', label: messages['contact.i18n-CAT-01074'] }
		],
		'Importing commercial goods [category=CAT-01008]': [
			{ value: 'First Time Importer [subCategory=CAT-01068]', label: messages['contact.i18n-CAT-01068'] }, 
			{ value: 'General importations [subCategory=CAT-01069]', label: messages['contact.i18n-CAT-01069'] }, 
			{ value: 'Help with Importer Program enrolment [subCategory=CAT-01189]', label: messages['contact.i18n-CAT-01189'] }, 
			{ value: 'Rulings [subCategory=CAT-01070]', label: messages['contact.i18n-CAT-01070'] }, 
			{ value: 'Temporary Imports/Remissions [subCategory=CAT-01072]', label: messages['contact.i18n-CAT-01072'] }, 
			{ value: 'Trade Agreements [subCategory=CAT-01073]', label: messages['contact.i18n-CAT-01073'] }, 
			{ value: 'Trade incentives [subCategory=CAT-01074]', label: messages['contact.i18n-CAT-01074'] }
		],
		'Storing commercial goods [category=CAT-01010]': [
			{ value: 'General guidelines for bonded warehouses trade incentives program [subCategory=CAT-01087]', label: messages['contact.i18n-CAT-01087'] }, 
			{ value: 'General guidelines for sufferance warehouses [subCategory=CAT-01088]', label: messages['contact.i18n-CAT-01088'] }
		],
		'Transporting/logistics commercial goods [category=CAT-01011]': [
			{ value: 'Guidelines for Transporting commercial goods for exportation abroad [subCategory=CAT-01089]', label: messages['contact.i18n-CAT-01089'] }, 
			{ value: 'Guidelines for Transporting commercial goods for importation to Canada [subCategory=CAT-01090]', label: messages['contact.i18n-CAT-01090'] }, 
			{ value: 'Guidelines for Transporting commercial goods through Canada or the US [subCategory=CAT-01091]', label: messages['contact.i18n-CAT-01091'] }, 
			{ value: 'Transporter obligations [subCategory=CAT-01093]', label: messages['contact.i18n-CAT-01093'] }
		],
		default: [
			{ value: '', label: messages['contact.i18n-selectFromTheList'] },
		],
	};

    return {
		commercialProgramOptions,
		iamOptions,
		authRepTypeOptions,
		categoryOptions,
		subcategoryOptionsForCategory,
		// getFieldAttributes,
		modeOfTransportOptions
	};
};