import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { IntlContext, FormattedMessage } from 'react-intl';
import { TextFieldWithLabel, SelectFieldWithLabel, RadioButtonsWithLabel } from '../../components/FormFields';
import { Formik, Form } from "formik";
import { getConstants } from '../../constants';
import PeopleFields from '../../components/PeopleFields'
import CommercialFields from '../../components/CommercialFields'
import * as Yup from 'yup';

// import { contact, inquiryPersonal, inquiryCssh, inquiryTccu, inquiryCfs, inquiryBistier } from './csformFiller';

import { UnitEnum, FieldEnum, SubCategoriesToFields,  } from '../../components/data';
import { postConfigHeader, scrollToFirstError, getElementValue } from '../../utils/formUtils';
import { currentLongLang } from '../../utils/miscUtils';
import { FormTypes } from '../../constants';
import Spinner from '../../components/Spinner'

function CSform({navigation}) {
    const [showSpinner, setShowSpinner] = useState(false);
	const { messages } = useContext(IntlContext);
	const constants = getConstants(messages);
	const setFlashError = navigation?.setFlashError;

	const CANADA_VALUE = constants.countriesForValidation['Canada'];
	const USA_VALUE = constants.countriesForValidation['United States'];

	useEffect(() => {
		setFlashError(null);
		document.title = messages['contact-us']
	},[])

	//Build the submit object with only the relevant fields
	function handleSubmit(values, resetForm) {
		const apiPostObj = createSubmitObject(values);
		
		// Post to API
		axios.post(window.env.apiUrlPrefix + "/inquiry", apiPostObj, postConfigHeader).then(function(response){
			console.debug("Response status:", response?.status, response);

			const confirmObj = createConfirmObject(values);
			const confirmPreamble = <>
				<h2>{messages['confirmation']}</h2>
				<div>{messages['contact.i18n-results-submission-confirmation']}</div>
			</>
			resetForm();
			navigation.navigate("Results", { results: confirmObj, preamble: confirmPreamble, form: FormTypes.INQUIRY});
		}).catch(function(error){
			console.error("ERROR POSTING INQUIRY", error);
            setFlashError({duration:-1, messages:[`${messages['http.500.content']} (${error})`]})
		}).finally(() => {
			setShowSpinner(false);
		});
	}
	
	/*
		TODO: the confirmObj should really be created directly from the submitObj to truly reflect what WAS submitted. The below code could get out of sync easily
	*/
    function createConfirmObject(values) {
		// Construct the navigationResultObj
		const confirmObj = {
			contact: {
				label: messages['contact.i18n-personalInfo'],
				data: [
					{ data: values["firstName"], label: messages['contact.i18n-firstName'] },
					{ data: values["lastName"], label: messages['contact.i18n-lastName'] },
					{ data: values["email"], label: messages['contact.i18n-emailAddress'] },
					{ data: values["telephone"], label: messages['contact.i18n-telephoneNum'] },
					{ data: values["telephoneExtension"], label: messages['contact.i18n-telephoneExt'] },
					{ data: getElementValue('countryOfResidence'), label: messages['country'] },
					{ data: getElementValue('provinceState'), label: messages['province-state'] },
					{ data: values["languagePreference"], label: messages['contact.en-languagePreference'] },
				]
			},
			questionTopic: {
				label: messages["topic"],
				data: [
					{ data: getElementValue('questionTopic'), label: messages['CIPS.i18n-whatYourRequestRegarding']}
				]
			},
			details: {
				label: messages["details"],
			},
			bizInfo: {
				label: messages['CIPS.i18n-buisnessInformation'],
			},
			description: {
                label: messages['description'],
                data: [
                    { data: values['detailedDescription'], label: messages['text']}
                ]
            }
		};

		//Construct dynamic details
		// Topic 1 - people
		if(values.questionTopic === 'people') { //TODO: Refactor with constants
			confirmObj.details.data = [
				{ data: getElementValue('statusInCanada'), label: messages['contact.i18n-statusInCanada'] },
				{ data: getElementValue('topic'), label: messages['contact.i18n-topic'] },
				{ data: getElementValue('thirdTopic'), label: messages['contact.i18n-topic-secondary-topic-label'] },
			]
		}
		// Topics 2-5
		else { 
			// Common fields
			confirmObj.details.data = [
				{ data: getElementValue('commercialProgram'), label: messages['contact.i18n-whatCommercialProgramAreYouInquiryingAbout'] },
				{ data: getElementValue('iam'), label: messages['contact.i18n-withRespectToTheCompanyThisQuestionIsFor_IAm'] },
				{ data: getElementValue('authRepType'), label: messages['contact.i18n-authorizedRepresentativeType'] },
				{ data: getElementValue('category-bistier2'), label: messages['contact.i18n-category'] },
				{ data: getElementValue('selectedSubCategory'), label: messages['contact.i18n-subCategory'] },
			]

			// Lookup business fields associated to the sub-category and add them
			let bizInfoData = [];
			const subcategories = SubCategoriesToFields[values.selectedSubCategory];

			subcategories?.requiredFields?.forEach(({fieldId}) => {
				bizInfoData.push({ data: getElementValue(fieldId), label: messages[`contact.i18n-${fieldId}`]});
			});
			subcategories?.optionalFields?.forEach(({fieldId}) => {
				bizInfoData.push({ data: getElementValue(fieldId), label: messages[`contact.i18n-${fieldId}`]});
			});
			if(bizInfoData.length > 0) {
				confirmObj.bizInfo.data = bizInfoData;
			}
		}

		return confirmObj;
	}

	const isFieldRequiredForSubcategory = (subCategoryId, fieldEnumKey) => {
		const subCategoryData = SubCategoriesToFields[subCategoryId];
		if (!subCategoryData) {
			// console.log(`Subcategory '${subCategoryId}' not found.`);
			return false; 
		}

		const isRequired = subCategoryData.requiredFields.some(field => field.fieldId === FieldEnum[fieldEnumKey].fieldId);
		return isRequired; // Return the actual result of your check
	};

	function createSubmitObject(values) {
		// Fields in all submissions (contact info)
		const { firstName, lastName, email, telephone, telephoneExtension, countryOfResidence, provinceState, languagePreference, detailedDescription } = values;
		let apiPostObj = { firstName, lastName, email, telephone, telephoneExtension, countryOfResidence, provinceState, languagePreference, detailedDescription };
		
		apiPostObj.questionTopic = constants.questionTopicsForSubmission[values.questionTopic]; //sub in required value for submission
		
		// Topic 1 - people
		if(values.questionTopic === 'people') { //TODO: Refactor with constants
			const { statusInCanada, topic, thirdTopic } = values;
			apiPostObj = { ...apiPostObj, statusInCanada, topic, thirdTopic, 
				group: UnitEnum.BIS //tells lambda what group the form is going to
			}
		}
		// Topics 2-5
		else {
			// Common fields
			const { commercialProgram, iam, authRepType, selectedSubCategory } = values;
			const subcategories = SubCategoriesToFields[selectedSubCategory];

			apiPostObj = { 
				...apiPostObj, 
				commercialProgram, 
				iam, 
				authRepType, 
				'category-bistier2': values['category-bistier2'],
				selectedSubCategory,
				group: subcategories?.unit
			}
			
			const bizFields = []; //hold keys in the order that they should appear in the email (required fields first, then optional)

			// Lookup business fields associated to the sub-category and add them
			subcategories?.requiredFields?.forEach(field => {
				const fieldValue = values[field.fieldId];
				apiPostObj[field.fieldId] = fieldValue;
				bizFields.push(field.fieldId);
			});
			subcategories?.optionalFields?.forEach(field => {
				const fieldValue = values[field.fieldId];
				if(!!fieldValue) { //don't bother including optional fields if they haven't been filled out
					apiPostObj[field.fieldId] = fieldValue;
					bizFields.push(field.fieldId);
			}
			});
			if(bizFields.length > 0) {
				apiPostObj.bizFields = bizFields;
			}
		}
		console.debug('SUBMIT', apiPostObj);
		return apiPostObj;
	}

	const generateConditionalRequiredTest = (fieldName) => {
		return Yup.string().test(
			`is-${fieldName}-required`
			, messages["common.required"] 
			, function (value) {
				const { selectedSubCategory } = this.parent;// accessing the value
				// selected in the form
				const isRequired = isFieldRequiredForSubcategory(selectedSubCategory, fieldName);

				// if(fieldName.toLowerCase().includes("number")){
				// 	const onlyNumberRegex = /^\d+$/;
				// 	const isValidNumber = onlyNumberRegex.test(value);
				// 	if (isRequired && !value) return this.createError({ message: messages["common.required"] });
				// 	if (!isValidNumber) return this.createError( {message: "please enter a number"})
				// }
				return isRequired ? !!value : true; // Only enforce requirement if the
				// field is marked as required
			}
		);
	};

	//Constants to make validation more efficient/readable
    const REQUIRED_STRING = Yup.string().required(messages["common.required"]); //easier reading for validationSchema
	const TOPIC_PEOPLE_REQUIRED_FIELD = Yup.string().when('questionTopic', {
		is: value => value === 'people'
		, then: REQUIRED_STRING
		, otherwise: Yup.string()
	});
	
	const TOPIC_NOT_PEOPLE_REQUIRED_FIELD = Yup.string().when('questionTopic', {
		is: value => value !== 'people'
		, then: REQUIRED_STRING
		, otherwise: Yup.string()
	});

	// *** VALIDATION
	// ! Remember, any changes here need to be reflected in the inquiry.json JSON schema
	const generateValidationSchema = (selectedSubCategory) => {
		return Yup.object({
			firstName: Yup.string().max(30).required(messages["common.required"])
			, lastName: Yup.string().max(30).required(messages["common.required"])
			, email: Yup.string().max(175).matches(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/, messages["common.mustBeAValidEmailAddress"]).required(messages["common.required"])
			, telephone: Yup.string().matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, messages["contact.i18n-numberHyphensPlusSignsSpacesAndRoundBrackets"]).max(17).required(messages["common.required"])
			, telephoneExtension: Yup.string().max(6)
			, countryOfResidence: REQUIRED_STRING
			, provinceState: Yup.string().when("countryOfResidence", {
				is: (country) => [CANADA_VALUE, USA_VALUE].includes(country),
				then: REQUIRED_STRING,
			})
			, languagePreference: REQUIRED_STRING
			, 'category-bistier2': Yup.string().when('questionTopic' , {
				is: value => value && value !== 'people'
				, then: REQUIRED_STRING
				, otherwise: Yup.string()
			})
			, selectedSubCategory: Yup.string().when('questionTopic' , {
				is: value => value && value!== 'people'
				, then: REQUIRED_STRING
				, otherwise: Yup.string()
			})
			, businessName: generateConditionalRequiredTest('businessName').max(60)
			, businessNumber: generateConditionalRequiredTest('businessNumber').max(9).matches(/^\d+$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbers"])
			, rmNumber: generateConditionalRequiredTest('rmNumber').max(4).matches(/^\d+$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbers"])
			, bondNumber: generateConditionalRequiredTest('bondNumber').max(25).matches(/^\d+$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbers"])
			, carrierCode: generateConditionalRequiredTest('carrierCode').max(4)
			, accountSecurityNumber: generateConditionalRequiredTest('accountSecurityNumber').max(5)
			, subOfficeLocation: generateConditionalRequiredTest('subOfficeLocation').max(4).matches(/^\d+$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbers"])
			, conveyanceReferenceNumber: generateConditionalRequiredTest('conveyanceReferenceNumber').max(40)
			, cargoControlNumber: generateConditionalRequiredTest('cargoControlNumber').max(40)
			, modeOfTransport: generateConditionalRequiredTest('modeOfTransport')
			, transactionNumber: generateConditionalRequiredTest('transactionNumber').max(14).matches(/^\d+$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbers"])
			, dateOfTransaction: generateConditionalRequiredTest('dateOfTransaction').max(10).matches(/^(?:\d{4}-(?:(0[13578]|1[02])-(0[1-9]|[1-2][0-9]|3[01])|(?:0[469]|11)-(?:0[1-9]|[1-2][0-9]|30)|02-(?:0[1-9]|1[0-9]|2[0-8]))|\d{2}(?:[2468][48]|[13579][26])-02-29|\d{2}(?:[0][48]|[13579][26])-02-29|\d{2}(?:[2468][0]|[13579][26])-02-29)$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-dateFormat"])
			, transactionAmount: generateConditionalRequiredTest('transactionAmount').max(8).matches(/^\$?[\d]{1,8}(\.\d{2})?$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbersWithUpTo2DecimalPlaces"])
			, statementOfAccountDate: generateConditionalRequiredTest('statementOfAccountDate').max(10).matches(/^(?:\d{4}-(?:(0[13578]|1[02])-(0[1-9]|[1-2][0-9]|3[01])|(?:0[469]|11)-(?:0[1-9]|[1-2][0-9]|30)|02-(?:0[1-9]|1[0-9]|2[0-8]))|\d{2}(?:[2468][48]|[13579][26])-02-29|\d{2}(?:[0][48]|[13579][26])-02-29|\d{2}(?:[2468][0]|[13579][26])-02-29)$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-dateFormat"])
			, statementOfAccountAmount: generateConditionalRequiredTest('statementOfAccountAmount').max(8).matches(/^\$?[\d]{1,8}(\.\d{2})?$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbersWithUpTo2DecimalPlaces"])
			, dailyNoticeDate: generateConditionalRequiredTest('dailyNoticeDate').max(10).matches(/^(?:\d{4}-(?:(0[13578]|1[02])-(0[1-9]|[1-2][0-9]|3[01])|(?:0[469]|11)-(?:0[1-9]|[1-2][0-9]|30)|02-(?:0[1-9]|1[0-9]|2[0-8]))|\d{2}(?:[2468][48]|[13579][26])-02-29|\d{2}(?:[0][48]|[13579][26])-02-29|\d{2}(?:[2468][0]|[13579][26])-02-29)$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-dateFormat"])
			, dailyNoticeAmount: generateConditionalRequiredTest('dailyNoticeAmount').max(8).matches(/^\$?[\d]{1,8}(\.\d{2})?$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbersWithUpTo2DecimalPlaces"])
			, previousTicketNumber: generateConditionalRequiredTest('previousTicketNumber').max(15)
			, chequeNumber: generateConditionalRequiredTest('chequeNumber').max(8)
			, dateOnCheque: generateConditionalRequiredTest('dateOnCheque').max(10).matches(/^(?:\d{4}-(?:(0[13578]|1[02])-(0[1-9]|[1-2][0-9]|3[01])|(?:0[469]|11)-(?:0[1-9]|[1-2][0-9]|30)|02-(?:0[1-9]|1[0-9]|2[0-8]))|\d{2}(?:[2468][48]|[13579][26])-02-29|\d{2}(?:[0][48]|[13579][26])-02-29|\d{2}(?:[2468][0]|[13579][26])-02-29)$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-dateFormat"])
			, chequeAmount: generateConditionalRequiredTest('chequeAmount').max(8).matches(/^\$?[\d]{1,8}(\.\d{2})?$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbersWithUpTo2DecimalPlaces"])
			, paymentAmount: generateConditionalRequiredTest('paymentAmount').max(8).matches(/^\$?[\d]{1,8}(\.\d{2})?$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-numbersWithUpTo2DecimalPlaces"])
			, paymentDate: generateConditionalRequiredTest('paymentDate').max(10).matches(/^(?:\d{4}-(?:(0[13578]|1[02])-(0[1-9]|[1-2][0-9]|3[01])|(?:0[469]|11)-(?:0[1-9]|[1-2][0-9]|30)|02-(?:0[1-9]|1[0-9]|2[0-8]))|\d{2}(?:[2468][48]|[13579][26])-02-29|\d{2}(?:[0][48]|[13579][26])-02-29|\d{2}(?:[2468][0]|[13579][26])-02-29)$/, messages["contact.i18n-invalidFormat"] + " - " + messages["contact.i18n-allowedCharacters"] + ": " + messages["contact.i18n-dateFormat"])
			, detailedDescription: Yup.string().when('questionTopic' , {
				is: value => value
				, then: Yup.string().max(3000, 'Description must be under 3000 characters.').required(messages["common.required"])
				, otherwise: Yup.string()
			})
			, questionTopic: REQUIRED_STRING

			//Inquiry - 1 - Personal/People
			, statusInCanada: TOPIC_PEOPLE_REQUIRED_FIELD
			, topic: TOPIC_PEOPLE_REQUIRED_FIELD
			, thirdTopic: Yup.string().when(['topic', 'questionTopic'], {
				is: (topic, questionTopic) => 
					questionTopic === 'people' && topic && 
						!['Currency [topic=currency]', 'Refunds and Appeals [topic=refundsAndAppeals]'].includes(topic),
				then: REQUIRED_STRING,
				otherwise: Yup.string(),
			})

			//Inquiry - 2-5 (non-personal/people)
			, commercialProgram: TOPIC_NOT_PEOPLE_REQUIRED_FIELD
			, iam: TOPIC_NOT_PEOPLE_REQUIRED_FIELD
			, authRepType: Yup.string().when('iam', {
				is: value => value === 'An authorized representative of the company [iam=888060000]' //TODO: Refactor with constants
				, then: REQUIRED_STRING
				, otherwise: Yup.string()
			})
		})
	};

	return (
		<>
            <Spinner className="spinner" show={showSpinner} />
			<section className="alert alert-warning">
				<p>{messages["contact.i18n-topOfPageNoProfanity"]}</p>
			</section>
			<details>
				<summary>{messages["contact.i18n-privacyDisclaimer"]}</summary>
				<p>{messages["contact.i18n-topOfPageTermsOfUse"]}</p>
				<p>
					{messages["contact.i18n-topOfPageTermsAndConditions"]}
					&nbsp;
					<a href={messages["contact.i18n-topOfPageTermsAndConditions-link-href"]}>
						{messages["contact.i18n-topOfPageTermsAndConditions-link-label"]}
					</a>
					.
				</p>
			</details>
			<Formik
				initialValues={{
					"category-bistier2": ""
					, accountSecurityNumber: ""
					, authType: ""
					, authRepType: ""
					, bondNumber: ""
					, businessName: ""
					, businessNumber: ""
					, cargoControlNumber: ""
					, carrierCode: ""
					, chequeAmount: ""
					, chequeNumber: ""
					, commercialProgram: ""
					, conveyanceReferenceNumber: ""
					, countryOfResidence: ""
					, dailyNoticeAmount: ""
					, dailyNoticeDate: ""
					, dateOfTransaction: ""
					, dateOnCheque: ""
					, detailedDescription: ""
					, email: ""
					, firstName: ""
					, iam: ""
					, languagePreference: ""
					, lastName: ""
					, modeOfTransport: ""
					, paymentAmount: ""
					, paymentDate: ""
					, previousTicketNumber: ""
					, provinceState: ""
					, questionTopic: ""
					, rmNumber: ""
					, selectedSubCategory: ""
					, statementOfAccountAmount: ""
					, statementOfAccountDate: ""
					, statusInCanada: ""
					, subOfficeLocation: ""
					, telephone: ""
					, telephoneExtension: ""
					, thirdTopic: ""
					, topic: ""
					, transactionAmount: ""
					, transactionNumber: ""

					// Form filler stuff for dev
					// , ...contact
					// , ...inquiryPersonal
					// , ...inquiryCssh
					// , ...inquiryTccu
					// , ...inquiryCfs
					// , ...inquiryBistier
				}}
				validationSchema={generateValidationSchema()}
				onSubmit={(values, { resetForm }) => {
					setFlashError(null)
					setShowSpinner(true);
					console.debug("VALUES", values);
					handleSubmit(values, resetForm);
				}}
			>
			{({ values, errors, touched, isSubmitting, setSubmitting }) => {
				if (isSubmitting && errors) {
					setFlashError(null)
					setSubmitting(false)
					scrollToFirstError(errors);
				}
				return (
					<Form>
						{/* Personal info fields */}
						<fieldset className="col-md-12 error-parent" style={{ paddingBottom: "30px" }}>
							<h3>
								<FormattedMessage id="contact.i18n-yourContactInformation" />
							</h3>
							<TextFieldWithLabel label={<FormattedMessage id="contact.i18n-firstName" />} id="firstName" name="firstName" required maxLength="30" inputColClass="col-md-4" labelColClass="col-md-4" /> 
							<TextFieldWithLabel label={<FormattedMessage id="contact.i18n-lastName" />} id="lastName" name="lastName" required maxLength="30" inputColClass="col-md-4" labelColClass="col-md-4" />
							<TextFieldWithLabel label={<FormattedMessage id="contact.en-emailAddress" />} id="email" name="email" required maxLength="175" inputColClass="col-md-4" labelColClass="col-md-4" />
							<TextFieldWithLabel label={<FormattedMessage id="contact.i18n-telephoneNum" />} id="telephone" name="telephone" required maxLength="17" inputColClass="col-md-4" labelColClass="col-md-4" />
							<TextFieldWithLabel label={<FormattedMessage id="contact.i18n-telephoneExt" />} id="telephoneExtension" name="telephoneExtension" maxLength="6" inputColClass="col-md-4" labelColClass="col-md-4" />

							<SelectFieldWithLabel
								id="countryOfResidence"
								name="countryOfResidence"
								fieldClassName="col-md-4"
								labelClassName="col-md-4"
								required
								label={<FormattedMessage id="country" />}
								options={[
									{ value: "", label: messages["contact.i18n-country-complaint-label"] }
									, ...constants.countryComplaint
								]}
							/>
							{ (values['countryOfResidence'] === CANADA_VALUE || values['countryOfResidence'] === USA_VALUE) && 
								<SelectFieldWithLabel
									id="provinceState"
									name="provinceState"
									fieldClassName="col-md-4"
									labelClassName="col-md-4"
									required
									label={<FormattedMessage id="province-state" />}
									options={[
										{ value: "", label: messages["contact.i18n-select"] },
										...(values['countryOfResidence'] === CANADA_VALUE ? 
											constants.provinceComplaint 
											:
											(values['countryOfResidence'] === USA_VALUE ? constants.stateComplaint : [])
										)
									]}
							/>}
							<RadioButtonsWithLabel
								id="languagePreference"
								name="languagePreference"
								strongLabel
								required
								label={<FormattedMessage id="contact.en-languagePreference" />}
								options={constants.clientLanguageInquiry}
								colMd4={{}}
								containerStyle={{float: "left" , width: "100%"}}
							/>
						</fieldset>
					
						{/* INQUIRY TOPIC */}
						<RadioButtonsWithLabel
							id="questionTopic"
							name="questionTopic"
							label={<FormattedMessage id="contact.i18n-whatYourRequestRegarding" />}
							options={constants.questionTopics}
							strongLabel
							required
							wrapperClassName="form-group col-md-12"
							labelColClass="control-label col-md-8 required"
							inputColClass="form-group col-md-12"
							radioWrapperClassName="form-group col-md-12"
							optionStyle={{}}
							wrapAround={{}}
							mrgnBottom={{}}
						/>
						{values.questionTopic === 'people' && <PeopleFields />}
						{!!values.questionTopic && values["questionTopic"] !== "people" &&
							<CommercialFields
								onSubmit={handleSubmit}
								selectedProgram={values["questionTopic"]}
								handleSubmit={handleSubmit} 
							/>
						} 
						{/* Form buttons */}
						{!!values.questionTopic &&
							<>
								<button type="submit" className="btn btn-primary form-button"> <FormattedMessage id="button.submit" /> </button>
								<button type="reset" name="reset" value="Reset" className="btn btn-default mrgn-lft-md form-button" ><FormattedMessage id="button.reset"/></button>
							</>
						}
					</Form>
				)
			}}
			</Formik>
		</>
	);
}

// CSform.path = window.env["api-url-prefix"] + "/csform-formulaire";
CSform.path = "csform-formulaire";
CSform.navigationOptions = {
	pageTitle: ""
	, breadcrumb: [
		<a href={`https://www.cbsa-asfc.gc.ca/menu-${currentLongLang()}.html`}><FormattedMessage id="breadcrumbs.cbsaHome"/></a>,
	]
	, breadcrumbTitle: <FormattedMessage id="contact-us"/>
};

export default CSform;   

