import React from 'react';
//navigation
import {
  createNavigator,
  SwitchRouter,
  SceneView
} from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import {scenes} from './scenes/Scenes'

//Internationalization and Localization
import {IntlProvider} from 'react-intl';
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

//Template Components
import Header from './templates/Header';
import GlobalAlerts from './templates/GlobalAlerts';
import DateModified from './templates/DateModified';
import Footer from './templates/Footer';

/* 
	TODO: Move styles to dedicated CSS sheets
	TODO: JSON validation models require more details to more closely match Yup validation
*/

export class TemplateView extends React.Component {
	constructor(props) {
		super(props);
			
		this.state = {
			versionNumber : props.version
			, dateModified : !!props.dateModified ? props.dateModified : Date.now()
			, messages : {'en': messages_en, 'fr': messages_fr}
			, flashError : null
		}
		
		this.setFlashError = this.setFlashError.bind(this);
	}
	
	currentPageIndex = -1;
	
	setFlashError(messages) {
		// this.setFlashMessages(messages, this.flashErrorAnimationTimerID, "flashError", this.setFlashError, "errorAlertTimerBar");
		this.setState({flashError: messages});
		document.getElementById('global-alerts-container')?.scrollIntoView({behavior: 'smooth'})
	}
	
	componentDidUpdate() {
		//checking if the page has changed and clearing out any global alerts from the previous page render 
		const { navigation } = this.props;
		if (this.currentPageIndex !== navigation?.state.index ?? this.currentPageIndex) {
			this.setState({flashError : null});
			this.currentPageIndex = navigation?.state.index;
		}
	}
	
	render() {
		const { descriptors, navigation } = this.props;
		const activeKey = navigation?.state.routes[navigation?.state.index].key;
		const descriptor = (!!descriptors && !!activeKey ? descriptors[activeKey] : {navigation: {}, options: {}, getComponent: ()=>{return "Home";}});
		descriptor.navigation.globalAlerts = { setFlashError : this.setFlashError };
		descriptor.navigation.setFlashError = this.setFlashError;

		const messages = this.state.messages[document?.documentElement?.lang ?? "en"]
		
		return (
			<IntlProvider locale={document?.documentElement?.lang ?? "en"} messages={messages}>
				<Header
					pageTitle={descriptor.options.pageTitle}
					breadcrumb={fillInMissingBreadcrumbParams(descriptor.options.breadcrumb, descriptor.state.params)}
					breadcrumbTitle={descriptor.options.breadcrumbTitle}
					currentMenuItem={activeKey}
				/>
				<main className="container" typeof="WebPageElement" property="mainContentOfPage">
					{(descriptor.options.pageTitle !== "" && descriptor.options.pageTitle !== undefined  ? (<h1 id="wb-cont" property="name">{descriptor.options.pageTitle}</h1>) : "")}
					<SceneView
						component={descriptor.getComponent()}
						navigation={descriptor.navigation}
					/>
					<span>&nbsp;</span> {/* TODO: this is a workaround: without this separation, errors from inquiry form will take over the entire page (?!!) */}
					<GlobalAlerts flashError={this.state.flashError} setFlashError={this.setFlashError} />
					<DateModified versionNumber={this.state.versionNumber} dateModified={this.state.dateModified} />
				</main>
				<Footer />
			</IntlProvider>
		);
	}
}

//*important* react-navigation is primarily for native webapps written with react-native, 
//we utilize the 'web-support' feature in regular react for a more consistent code base between website development and native app development
//for more info on how this works, see: https://reactnavigation.org/docs/3.x/web-support/
const AppNavigator = createNavigator(
	TemplateView,
	SwitchRouter(scenes),
	{}
);

const App = createBrowserApp(AppNavigator);

export default App;

//helper functions
function fillInMissingBreadcrumbParams(breadcrumbs, params) {
	
	let updatedBreadcrumbs = [...breadcrumbs];
	
	updatedBreadcrumbs.forEach((breadcrumb) => {
		if (!!breadcrumbs && breadcrumb?.props?.params) {
			for (const [key, value] of Object.entries(breadcrumb.props.params)) {
				if (value === "{"+key+"}") {
					breadcrumb.props.params[key] = params[key];
				}
			}
		}
		return true;
	});
	return updatedBreadcrumbs;
}