import React, {useContext} from 'react'
import {FormattedMessage, IntlContext} from 'react-intl';
import { useFormikContext } from "formik";
import { SelectFieldWithLabel, TextFieldWithLabel } from './FormFields';
import { getConstants } from '../constants';
import ProvinceStateDropdown from '../components/ProvinceStateDropdown';


export default function ThirdPartyRepDetails(props) {
    const { values } = useFormikContext();
	const { messages } = useContext(IntlContext);
    const constants = getConstants(messages);

    const clientLanguage = constants["clientLanguage"];
    const clientCommunicationMethod = constants["clientCommunicationMethod"];
    const countryComplaint = constants["countryComplaint"];
    const orderCommunication = constants["orderCommunication"];

	return (
    	<>
    		{values["thirdPartyAuthorized"] ?
    		<>
    			{values["thirdPartyAuthorized"][0] === "yes" ?
    			<div id="thirdPartyDetails" className="panel panel-primary error-parent">
    				<div className="panel-heading">
    					<h2 className="panel-title">
    						<FormattedMessage id="third-party" />
    					</h2>
    				</div>
    				<div className="panel-body">
						<div style={{margin: '1rem 0 2rem 0'}}>
							<FormattedMessage id="please-complete-the-following-section" />
						</div>

    					<div className="row">
							<TextFieldWithLabel id="firstNameComplaintRep" name="firstNameComplaintRep" wrapperClassName="form-group col-md-4" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-firstName"]} />
							<TextFieldWithLabel id="familyNameComplaintRep" name="familyNameComplaintRep" wrapperClassName="form-group col-md-4" wrapperStyle={{}} maxLength="80" required={true} label={messages["CIPS.i18n-lastName"]} />
							<TextFieldWithLabel id="firmOrganizationComplaint" name="firmOrganizationComplaint" wrapperClassName="form-group col-md-4" wrapperStyle={{}} maxLength="80" required={false} label={messages["firmorganization"]} />
    					</div>
    					<div className="row">
    						<SelectFieldWithLabel id="clientCommunicationMethodRep" name="clientCommunicationMethodRep" wrapperStyle={{}} wrapperClassName="form-group col-md-4" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-communicationMethod-label"]} options={[ {value:"", label: messages["CIPS.i18n-communicationMethod"]} , ...clientCommunicationMethod ]} />
    						<SelectFieldWithLabel id="clientLanguageComplaintRep" name="clientLanguageComplaintRep" wrapperStyle={{}} wrapperClassName="form-group col-md-4" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-languagePreference-label"]} options={[ {value:"", label: messages["CIPS.i18n-languagePreference"]} , ...clientLanguage ]} />
    						<SelectFieldWithLabel id="orderCommunicationRep" name="orderCommunicationRep" wrapperStyle={{}} wrapperClassName="form-group col-md-4" fieldClassName={{}} labelClassName="control-label" required={true} label={messages["CIPS.i18n-communicationOrder-label"]} options={[ {value:"", label: messages["CIPS.i18n-communicationOrder"]} , ...orderCommunication ]} />
    					</div>
    					<div className="row">
							<TextFieldWithLabel id="complaintEmailRep" name="complaintEmailRep" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={false} label={messages["email"]} />
							<TextFieldWithLabel id="phoneNumberComplaintRep" name="phoneNumberComplaintRep" wrapperClassName="form-group col-md-6" wrapperStyle={{}} maxLength="80" required={false} label={messages["telephone-number"]} />
    					</div>
    					<div className="row">
							<TextFieldWithLabel id="address1ComplaintRep" name="address1ComplaintRep" wrapperClassName="form-group col-md-8" wrapperStyle={{}} maxLength="80" required={false} label={messages["address-street-x-x"]} />
							<TextFieldWithLabel id="cityComplaintRep" name="cityComplaintRep" wrapperClassName="form-group col-md-4" wrapperStyle={{}} maxLength="80" required={false} label={messages["city"]} />
    					</div>
    					<div className="row">
    						<SelectFieldWithLabel id="countryComplaintRep" name="countryComplaintRep" wrapperStyle={{}} wrapperClassName="form-group col-md-4" fieldClassName={{}} labelClassName="control-label" required={false} label={messages["CIPS.i18n-country-complaint-label"]} options={[ {value:"", label: messages["CIPS.i18n-country-complaint"]} , ...countryComplaint ]} />
    						<ProvinceStateDropdown countryFieldId="countryComplaintRep" provinceId="provinceComplaintRep" stateId="stateComplaintRep" provinceStateComplaintId="provinceStateComplaintRep" wrapperClass="form-group col-md-4"/>
							<TextFieldWithLabel id="postalCodeComplaintRep" name="postalCodeComplaintRep" wrapperClassName="form-group col-md-4" wrapperStyle={{}} maxLength="80" required={false} label={messages["postalzip-code"]} />
    					</div>
						{/* TODO: open in a new window, use the new-tab icon */}
    					<p><FormattedMessage id="publications-authorization-representative" values={{strong: (chunks) => <strong>{chunks}</strong>, a: (chunks) => <a href={"https://www.cbsa-asfc.gc.ca/publications/forms-formulaires/bsf745-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html"}>{chunks}</a>}} />
    					</p>
    				</div>
    			</div>
    			:
    			<>
    			</>
    			}
    		</>
    		:
    		<>
    		</>
    		}
    	</>
    )
}
