import React, {useContext} from 'react';
import {FormattedMessage, IntlContext} from 'react-intl';


// documentation:
//   in the file where you want the component:
//     step 1:
//       import component using correct path:
//         import MagnifiquePopup from './components/MagnifiquePopup.js';
//     step 2:
//       add useState's:
//         const [appearPopup, setAppearPopup] = useState(false);
//         const [goahead, setGoahead] = useState(false);
//     step 3:
//       add useEffect:
//         useEffect(() => {
//           if (goahead === true) {
//             insertFunction();
//           }
//         }, [goahead]);
//     step 4:
//       set up a button or something similar that will trigger the popup:
//         <button onClick={() => setAppearPopup(true)}></button>
//     step 5:
//       add component inside return() and adjust the attribute values as needed:
//         <MagnifiquePopup 
//           id="signoutConfirmation" 
//           trigger={appearPopup} 
//           setTrigger={setAppearPopup} 
//           setGo={setGoahead} 
//           header={<FormattedMessage id="message.signout.confirmation" />} 
//           body={<FormattedMessage id="message.signout.explanation" />} 
//         />
//		 optionally, add these attributes:
//		   setBack={setBackdown}
//		   cancelButton={<FormattedMessage id="button.no" />} 
//		   continueButton={<FormattedMessage id="button.yes" />} 
//   inside index.css:
//	   step 6:
//       add the following CSS:
//		   .popup {
//			 position: fixed;
//			 top: 0;
//			 left: 0;
//			 width: 100%;
//			 height: 100vh;
//			 background-color: rgba(0, 0, 0, 0.5);
//			
//			 display: flex;
//			 justify-content: center;
//			 align-items: center;
//			 z-index: 300000;
//		   }


function MagnifiquePopup(props) {
	
	const { messages } = useContext(IntlContext);
	
	return (props.trigger) ? (
		<div className="popup">
			<section id={props.id} className="modal-dialog modal-content overlay-def popup-section" >
				{/* { props.children } */}
				<header className="modal-header">
					<h3 className="modal-title">{ props.header }</h3>
				</header>

				<div className="modal-body">
					{ props.body }
				</div>

				<div className="modal-footer">
					<span 
					role="button" id="btnCloseOverlay" className="btn btn-sm btn-primary pull-left" title={messages["button.title.closeOverlay"]}
					onClick={() => {
						props.setTrigger(false);
						if (props.setBack) {
							props.setBack(true);
						}
					}}
					>
					{props.cancelButton ? props.cancelButton : <FormattedMessage id="button.cancel"/>}
					</span>

					{props.hideContinue ? <></> : 
						<span 
							role="button" id="btnContinue" className="btn btn-sm btn-danger pull-right" title={messages["button.continue"]}
							onClick={() => {props.setTrigger(false); props.setGo(true);}}
						>
							{props.continueButton ? props.continueButton : <FormattedMessage id="button.continue"/>}
						</span>
					}
				</div>
			</section>
		</div>
	) : <></>;
}

export default MagnifiquePopup;