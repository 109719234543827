export const FormTypes = {
    INQUIRY: 'inquiry',
    COMPLIMENT: 'compliment',
    COMPLAINT: 'complaint'
}

//! Note: changes here must be reflected in JSON validation schemas
// TODO: many of these select options are missing from JSON validation, should move them over
export const getConstants = (messages) => ({
    feedbackTypes : [
        { value:"Compliment", label: messages["Compliment"]}
        , { value:"Complaint", label: messages["Complaint"]}
    ]
    , clientLanguage : [
        { value:"english [client-lang=eng]", label: messages["english"]}
        , { value:"french [client-lang=fra]", label: messages["french"]}
    ]
    , clientLanguageInquiry : [
        { value:"English", label: messages["contact.i18n-english"]}
        , { value:"French", label: messages["contact.i18n-french"]}
    ]
    , clientCommunicationMethod : [
        { value:"email [com-method=email]", label: messages["email"]}
        , { value:"telephone [com-method=tel]", label: messages["telephone"]}
        , { value:"mail [com-method=mail]", label: messages["mail"]}
    ]
    // Updates to the values here must be reflected in questionTopicsForSubmission
    , questionTopics: [
        { value: "people", label: messages["contact.i18n-commercialProgram-bistier1"] },
        { value: 'cssh', label: messages["contact.i18n-commercialProgram-ccsh"] },
        { value: 'tccu', label: messages["contact.i18n-commercialProgram-tccu"] },
        { value: 'cfs', label: messages["contact.i18n-commercialProgram-cfs"] },
        { value: 'bistier2', label: messages["contact.i18n-commercialProgram-bistier2"] },
    ]
    // This is just for translating questionTopics to the format required for submission
    // Code was already all setup using the original values so doing this at the end was just easier and less awkward
    , questionTopicsForSubmission: {
        people: "Personal travel e.g. visiting Canada, visiting abroad, importing/exporting personal goods; where is my package; bringing personal goods while working or attending school in Canada [pleaseTellUsIfYourQuestionIsAboutA=people]", 
        cssh: "Help with the CBSA Assessment and Revenue Management (CARM) project (e.g., CARM Client Portal (CCP)); program changes such as importer release prior to payment (RPP) under CARM; API features for tariff, exchange rates, duty & tax calculator; online rulings; EDI/API Commercial Accounting Declaration (CAD) (e.g., enrolling and testing)  [pleaseTellUsIfYourQuestionIsAboutA=commercial]", 
        tccu: "Help with electronic data interchange (EDI) client enrollment for Advance Commercial Information (ACI), release, cargo and warehouse arrivals, G7 export, release and other ACI notifications and the EDI communication methods (e.g., Direct connection to the CBSA, requesting a customs internet gateway (CIG) certificate). Assistance with the eManifest, Canadian Export Reporting System (CERS) or Trusted Trader online portals and EDI commercial documents (e.g., ACI, Conveyance and Warehouse Arrival Messages (CACM/WACM), G7 Export, Release, RNS, Notifications, and the Canada Customs Coding form (B3) [pleaseTellUsIfYourQuestionIsAboutA=commercial]", 
        cfs: "Financial account activities (e.g., payments, account balance, offsets, credits, financial security, EDI and CCP Daily Notice (DN) and Statement of Account (SOA), etc.) [pleaseTellUsIfYourQuestionIsAboutA=commercial]", 
        bistier2: "Information on commercial programs and regulations e.g. Departmental Memoranda, Customs Notices, Importing/Exporting requirements, Transporter Obligation and Trade Incentives [pleaseTellUsIfYourQuestionIsAboutA=commercial]" 
    }
    , countriesForValidation : {
        "Canada" : "Canada [country=CA]"
        ,"United States" : "United States [country=US]" 
    }
    , countryComplaint : [
        { value: "Canada [country=CA]", label: messages["country.Canada"]}
        , { value: "United States [country=US]", label: messages["country.United States"]}
        , { value: "Afghanistan [country=AF]", label: messages["country.Afghanistan"]}
        , { value: "Aland Islands [country=AX]", label: messages["country.Aland Islands"]}
        , { value: "Albania [country=AL]", label: messages["country.Albania"]}
        , { value: "Algeria [country=DZ]", label: messages["country.Algeria"]}
        , { value: "American Samoa [country=AS]", label: messages["country.American Samoa"]}
        , { value: "Andorra [country=AD]", label: messages["country.Andorra"]}
        , { value: "Angola [country=AO]", label: messages["country.Angola"]}
        , { value: "Anguilla [country=AI]", label: messages["country.Anguilla"]}
        , { value: "Antarctica [country=AQ]", label: messages["country.Antarctica"]}
        , { value: "Antigua and Barbuda [country=AG]", label: messages["country.Antigua and Barbuda"]}
        , { value: "Argentina [country=AR]", label: messages["country.Argentina"]}
        , { value: "Armenia [country=AM]", label: messages["country.Armenia"]}
        , { value: "Aruba [country=AW]", label: messages["country.Aruba"]}
        , { value: "Australia [country=AU]", label: messages["country.Australia"]}
        , { value: "Austria [country=AT]", label: messages["country.Austria"]}
        , { value: "Azerbaijan [country=AZ]", label: messages["country.Azerbaijan"]}
        , { value: "Bahamas [country=BS]", label: messages["country.Bahamas"]}
        , { value: "Bahrain [country=BH]", label: messages["country.Bahrain"]}
        , { value: "Bangladesh [country=BD]", label: messages["country.Bangladesh"]}
        , { value: "Barbados [country=BB]", label: messages["country.Barbados"]}
        , { value: "Belarus [country=BY]", label: messages["country.Belarus"]}
        , { value: "Belgium [country=BE]", label: messages["country.Belgium"]}
        , { value: "Belize [country=BZ]", label: messages["country.Belize"]}
        , { value: "Benin [country=BJ]", label: messages["country.Benin"]}
        , { value: "Bermuda [country=BM]", label: messages["country.Bermuda"]}
        , { value: "Bhutan [country=BT]", label: messages["country.Bhutan"]}
        , { value: "Bolivia [country=BO]", label: messages["country.Bolivia"]}
        , { value: "Bosnia and Herzegovina [country=BA]", label: messages["country.Bosnia and Herzegovina"]}
        , { value: "Botswana [country=BW]", label: messages["country.Botswana"]}
        , { value: "Bouvet Island [country=BV]", label: messages["country.Bouvet Island"]}
        , { value: "Brazil [country=BR]", label: messages["country.Brazil"]}
        , { value: "British Indian Ocean Territory [country=IO]", label: messages["country.British Indian Ocean Territory"]}
        , { value: "Brunei Darussalam [country=BN]", label: messages["country.Brunei Darussalam"]}
        , { value: "Bulgaria [country=BG]", label: messages["country.Bulgaria"]}
        , { value: "Burkina Faso [country=BF]", label: messages["country.Burkina Faso"]}
        , { value: "Burundi [country=BI]", label: messages["country.Burundi"]}
        , { value: "Cape Verde [country=KH]", label: messages["country.Cape Verde"]}
        , { value: "Cambodia [country=CM]", label: messages["country.Cambodia"]}
        , { value: "Cameroon [country=CV]", label: messages["country.Cameroon"]}
        , { value: "Cayman Islands [country=KY]", label: messages["country.Cayman Islands"]}
        , { value: "Central African Republic [country=CF]", label: messages["country.Central African Republic"]}
        , { value: "Chad [country=TD]", label: messages["country.Chad"]}
        , { value: "Chile [country=CL]", label: messages["country.Chile"]}
        , { value: "China [country=CN]", label: messages["country.China"]}
        , { value: "Christmas Island [country=CX]", label: messages["country.Christmas Island"]}
        , { value: "Cocos (Keeling) Islands [country=CC]", label: messages["country.Cocos (Keeling) Islands"]}
        , { value: "Colombia [country=CO]", label: messages["country.Colombia"]}
        , { value: "Comoros [country=KM]", label: messages["country.Comoros"]}
        , { value: "Congo [country=CG]", label: messages["country.Congo"]}
        , { value: "Cook Islands [country=CK]", label: messages["country.Cook Islands"]}
        , { value: "Costa Rica [country=CR]", label: messages["country.Costa Rica"]}
        , { value: "Côte d'Ivoire [country=CI]", label: messages["country.Côte d'Ivoire"]}
        , { value: "Croatia [country=HR]", label: messages["country.Croatia"]}
        , { value: "Cuba [country=CU]", label: messages["country.Cuba"]}
        , { value: "Cyprus [country=CY]", label: messages["country.Cyprus"]}
        , { value: "Czech Republic [country=CZ]", label: messages["country.Czech Republic"]}
        , { value: "Democratic Republic of the Congo [country=CD]", label: messages["country.Democratic Republic of the Congo"]}
        , { value: "Denmark [country=DK]", label: messages["country.Denmark"]}
        , { value: "Djibouti [country=DJ]", label: messages["country.Djibouti"]}
        , { value: "Dominica [country=DM]", label: messages["country.Dominica"]}
        , { value: "Dominican Republic [country=DO]", label: messages["country.Dominican Republic"]}
        , { value: "East Timor [country=TP]", label: messages["country.East Timor"]}
        , { value: "Ecuador [country=EC]", label: messages["country.Ecuador"]}
        , { value: "Egypt [country=EG]", label: messages["country.Egypt"]}
        , { value: "El Salvador [country=SV]", label: messages["country.El Salvador"]}
        , { value: "Equatorial Guinea [country=GQ]", label: messages["country.Equatorial Guinea"]}
        , { value: "Eritrea [country=ER]", label: messages["country.Eritrea"]}
        , { value: "Estonia [country=EE]", label: messages["country.Estonia"]}
        , { value: "Ethiopia [country=ET]", label: messages["country.Ethiopia"]}
        , { value: "Faeroe Islands [country=FO]", label: messages["country.Faeroe Islands"]}
        , { value: "Falkland Islands (Malvinas) [country=FK]", label: messages["country.Falkland Islands (Malvinas)"]}
        , { value: "Fiji [country=FJ]", label: messages["country.Fiji"]}
        , { value: "Finland [country=FI]", label: messages["country.Finland"]}
        , { value: "France [country=FR]", label: messages["country.France"]}
        , { value: "French Guiana [country=GF]", label: messages["country.French Guiana"]}
        , { value: "French Polynesia [country=PF]", label: messages["country.French Polynesia"]}
        , { value: "French Southern Territories [country=TF]", label: messages["country.French Southern Territories"]}
        , { value: "Gabon [country=GA]", label: messages["country.Gabon"]}
        , { value: "Gambia [country=GM]", label: messages["country.Gambia"]}
        , { value: "Georgia [country=GE]", label: messages["country.Georgia"]}
        , { value: "Germany [country=DE]", label: messages["country.Germany"]}
        , { value: "Ghana [country=GH]", label: messages["country.Ghana"]}
        , { value: "Gibraltar [country=GI]", label: messages["country.Gibraltar"]}
        , { value: "Greece [country=GR]", label: messages["country.Greece"]}
        , { value: "Greenland [country=GL]", label: messages["country.Greenland"]}
        , { value: "Grenada [country=GD]", label: messages["country.Grenada"]}
        , { value: "Guadeloupe [country=GP]", label: messages["country.Guadeloupe"]}
        , { value: "Guatemala [country=GU]", label: messages["country.Guatemala"]}
        , { value: "Guam [country=GT]", label: messages["country.Guam"]}
        , { value: "Guernsey [country=GG]", label: messages["country.Guernsey"]}
        , { value: "Guinea [country=GW]", label: messages["country.Guinea"]}
        , { value: "Guinea-Bissau [country=GN]", label: messages["country.Guinea-Bissau"]}
        , { value: "Guyana [country=GY]", label: messages["country.Guyana"]}
        , { value: "Haiti [country=HT]", label: messages["country.Haiti"]}
        , { value: "Heard and McDonald Islands [country=HM]", label: messages["country.Heard and McDonald Islands"]}
        , { value: "Honduras [country=HN]", label: messages["country.Honduras"]}
        , { value: "Hong Kong [country=HK]", label: messages["country.Hong Kong"]}
        , { value: "Hungary [country=HU]", label: messages["country.Hungary"]}
        , { value: "Iceland [country=IS]", label: messages["country.Iceland"]}
        , { value: "India [country=IN]", label: messages["country.India"]}
        , { value: "Indonesia [country=ID]", label: messages["country.Indonesia"]}
        , { value: "Iran [country=IR]", label: messages["country.Iran"]}
        , { value: "Iraq [country=IQ]", label: messages["country.Iraq"]}
        , { value: "Ireland [country=IE]", label: messages["country.Ireland"]}
        , { value: "Isle of Man [country=IM]", label: messages["country.Isle of Man"]}
        , { value: "Israel [country=IL]", label: messages["country.Israel"]}
        , { value: "Italy [country=IT]", label: messages["country.Italy"]}
        , { value: "Jamaica [country=JM]", label: messages["country.Jamaica"]}
        , { value: "Japan [country=JP]", label: messages["country.Japan"]}
        , { value: "Jersey [country=JE]", label: messages["country.Jersey"]}
        , { value: "Jordan [country=JO]", label: messages["country.Jordan"]}
        , { value: "Kazakhstan [country=KZ]", label: messages["country.Kazakhstan"]}
        , { value: "Kenya [country=KE]", label: messages["country.Kenya"]}
        , { value: "Kiribati [country=KI]", label: messages["country.Kiribati"]}
        , { value: "Korea, Democratic People's Republic of [country=KP]", label: messages["country.Korea, Democratic People's Republic of"]}
        , { value: "Korea, South [country=KR]", label: messages["country.Korea, South"]}
        , { value: "Kosovo [country=XK]", label: messages["country.Kosovo"]}
        , { value: "Kuwait [country=KW]", label: messages["country.Kuwait"]}
        , { value: "Kyrgyzstan [country=KG]", label: messages["country.Kyrgyzstan"]}
        , { value: "Laos [country=LA]", label: messages["country.Laos"]}
        , { value: "Latvia [country=LV]", label: messages["country.Latvia"]}
        , { value: "Lebanon [country=LB]", label: messages["country.Lebanon"]}
        , { value: "Lesotho [country=LS]", label: messages["country.Lesotho"]}
        , { value: "Liberia [country=LR]", label: messages["country.Liberia"]}
        , { value: "Libya [country=LY]", label: messages["country.Libya"]}
        , { value: "Liechtenstein [country=LI]", label: messages["country.Liechtenstein"]}
        , { value: "Lithuania [country=LT]", label: messages["country.Lithuania"]}
        , { value: "Luxembourg [country=LU]", label: messages["country.Luxembourg"]}
        , { value: "Macao [country=MO]", label: messages["country.Macao"]}
        , { value: "Macedonia [country=MK]", label: messages["country.Macedonia"]}
        , { value: "Madagascar [country=MG]", label: messages["country.Madagascar"]}
        , { value: "Malawi [country=MW]", label: messages["country.Malawi"]}
        , { value: "Malaysia [country=MY]", label: messages["country.Malaysia"]}
        , { value: "Maldives [country=MV]", label: messages["country.Maldives"]}
        , { value: "Mali [country=ML]", label: messages["country.Mali"]}
        , { value: "Malta [country=MT]", label: messages["country.Malta"]}
        , { value: "Marshall Islands [country=MH]", label: messages["country.Marshall Islands"]}
        , { value: "Martinique [country=MQ]", label: messages["country.Martinique"]}
        , { value: "Mauritania [country=MR]", label: messages["country.Mauritania"]}
        , { value: "Mauritius [country=MU]", label: messages["country.Mauritius"]}
        , { value: "Mayotte [country=YT]", label: messages["country.Mayotte"]}
        , { value: "Mexico [country=MX]", label: messages["country.Mexico"]}
        , { value: "Micronesia [country=FM]", label: messages["country.Micronesia"]}
        , { value: "Moldova [country=MD]", label: messages["country.Moldova"]}
        , { value: "Monaco [country=MC]", label: messages["country.Monaco"]}
        , { value: "Mongolia [country=MN]", label: messages["country.Mongolia"]}
        , { value: "Montenegro [country=ME]", label: messages["country.Montenegro"]}
        , { value: "Montserrat [country=MS]", label: messages["country.Montserrat"]}
        , { value: "Morocco [country=MA]", label: messages["country.Morocco"]}
        , { value: "Mozambique [country=MZ]", label: messages["country.Mozambique"]}
        , { value: "Myanmar [country=MM]", label: messages["country.Myanmar"]}
        , { value: "Namibia [country=NA]", label: messages["country.Namibia"]}
        , { value: "Nauru [country=NR]", label: messages["country.Nauru"]}
        , { value: "Nepal [country=NP]", label: messages["country.Nepal"]}
        , { value: "Netherlands [country=NL]", label: messages["country.Netherlands"]}
        , { value: "Neutral Zone [country=NT]", label: messages["country.Neutral Zone"]}
        , { value: "New Caledonia [country=NC]", label: messages["country.New Caledonia"]}
        , { value: "New Zealand [country=NZ]", label: messages["country.New Zealand"]}
        , { value: "Nicaragua [country=NI]", label: messages["country.Nicaragua"]}
        , { value: "Niger [country=NE]", label: messages["country.Niger"]}
        , { value: "Nigeria [country=NG]", label: messages["country.Nigeria"]}
        , { value: "Niue [country=NU]", label: messages["country.Niue"]}
        , { value: "Norfolk Island [country=NF]", label: messages["country.Norfolk Island"]}
        , { value: "Northern Marianas Islands [country=MP]", label: messages["country.Northern Marianas Islands"]}
        , { value: "Norway [country=NO]", label: messages["country.Norway"]}
        , { value: "Oman [country=OM]", label: messages["country.Oman"]}
        , { value: "Pakistan [country=PK]", label: messages["country.Pakistan"]}
        , { value: "Palau [country=PW]", label: messages["country.Palau"]}
        , { value: "Palestinian Territory [country=PS]", label: messages["country.Palestinian Territory"]}
        , { value: "Panama [country=PA]", label: messages["country.Panama"]}
        , { value: "Papua New Guinea [country=PG]", label: messages["country.Papua New Guinea"]}
        , { value: "Paraguay [country=PY]", label: messages["country.Paraguay"]}
        , { value: "Peru [country=PE]", label: messages["country.Peru"]}
        , { value: "Philippines [country=PH]", label: messages["country.Philippines"]}
        , { value: "Pitcairn Islands [country=PN]", label: messages["country.Pitcairn Islands"]}
        , { value: "Poland [country=PL]", label: messages["country.Poland"]}
        , { value: "Portugal [country=PT]", label: messages["country.Portugal"]}
        , { value: "Puerto Rico [country=PR]", label: messages["country.Puerto Rico"]}
        , { value: "Qatar [country=QA]", label: messages["country.Qatar"]}
        , { value: "Reunion [country=RE]", label: messages["country.Reunion"]}
        , { value: "Romania [country=RO]", label: messages["country.Romania"]}
        , { value: "Russia [country=RU]", label: messages["country.Russia"]}
        , { value: "Rwanda [country=RW]", label: messages["country.Rwanda"]}
        , { value: "Saint Kitts and Nevis [country=KN]", label: messages["country.Saint Kitts and Nevis"]}
        , { value: "Saint Lucia [country=LC]", label: messages["country.Saint Lucia"]}
        , { value: "Saint Vincent and the Grenadines [country=VC]", label: messages["country.Saint Vincent and the Grenadines"]}
        , { value: "Samoa [country=WS]", label: messages["country.Samoa"]}
        , { value: "San Marino [country=SM]", label: messages["country.San Marino"]}
        , { value: "Sao Tome and Principe [country=ST]", label: messages["country.Sao Tome and Principe"]}
        , { value: "Saudi Arabia [country=SA]", label: messages["country.Saudi Arabia"]}
        , { value: "Senegal [country=SN]", label: messages["country.Senegal"]}
        , { value: "Serbia [country=RS]", label: messages["country.Serbia"]}
        , { value: "Seychelles [country=SC]", label: messages["country.Seychelles"]}
        , { value: "Sierra Leone [country=SL]", label: messages["country.Sierra Leone"]}
        , { value: "Singapore [country=SG]", label: messages["country.Singapore"]}
        , { value: "Slovakia [country=SK]", label: messages["country.Slovakia"]}
        , { value: "Slovenia [country=SI]", label: messages["country.Slovenia"]}
        , { value: "Solomon Islands [country=SB]", label: messages["country.Solomon Islands"]}
        , { value: "Somalia [country=SO]", label: messages["country.Somalia"]}
        , { value: "South Africa [country=ZA]", label: messages["country.South Africa"]}
        , { value: "South Georgia and the South Sandwich Islands [country=GS]", label: messages["country.South Georgia and the South Sandwich Islands"]}
        , { value: "Spain [country=ES]", label: messages["country.Spain"]}
        , { value: "Sri Lanka [country=LK]", label: messages["country.Sri Lanka"]}
        , { value: "St. Helene [country=SH]", label: messages["country.St. Helene"]}
        , { value: "St. Pierre and Miquelon [country=PM]", label: messages["country.St. Pierre and Miquelon"]}
        , { value: "Sudan [country=SD]", label: messages["country.Sudan"]}
        , { value: "Sudan, South [country=SS]", label: messages["country.Sudan, South"]}
        , { value: "Suriname [country=SR]", label: messages["country.Suriname"]}
        , { value: "Svalbard and Jan Mayen Islands [country=SJ]", label: messages["country.Svalbard and Jan Mayen Islands"]}
        , { value: "Swaziland [country=SZ]", label: messages["country.Swaziland"]}
        , { value: "Sweden [country=SE]", label: messages["country.Sweden"]}
        , { value: "Switzerland [country=CH]", label: messages["country.Switzerland"]}
        , { value: "Syria [country=SY]", label: messages["country.Syria"]}
        , { value: "Taiwan [country=TW]", label: messages["country.Taiwan"]}
        , { value: "Tajikistan [country=TJ]", label: messages["country.Tajikistan"]}
        , { value: "Tanzania [country=TZ]", label: messages["country.Tanzania"]}
        , { value: "Thailand [country=TH]", label: messages["country.Thailand"]}
        , { value: "Togo [country=TG]", label: messages["country.Togo"]}
        , { value: "Tokelau [country=TK]", label: messages["country.Tokelau"]}
        , { value: "Tonga [country=TO]", label: messages["country.Tonga"]}
        , { value: "Trinidad and Tobago [country=TT]", label: messages["country.Trinidad and Tobago"]}
        , { value: "Tunisia [country=TN]", label: messages["country.Tunisia"]}
        , { value: "Turkey [country=TR]", label: messages["country.Turkey"]}
        , { value: "Turkmenistan [country=TM]", label: messages["country.Turkmenistan"]}
        , { value: "Turks and Caicos Islands [country=TC]", label: messages["country.Turks and Caicos Islands"]}
        , { value: "Tuvalu [country=TV]", label: messages["country.Tuvalu"]}
        , { value: "Uganda [country=UG]", label: messages["country.Uganda"]}
        , { value: "Ukraine [country=UA]", label: messages["country.Ukraine"]}
        , { value: "United Arab Emirates [country=AE]", label: messages["country.United Arab Emirates"]}
        , { value: "United Kingdom [country=GB]", label: messages["country.United Kingdom"]}
        , { value: "Uruguay [country=UY]", label: messages["country.Uruguay"]}
        , { value: "Uzbekistan [country=UZ]", label: messages["country.Uzbekistan"]}
        , { value: "Vanuatu [country=VU]", label: messages["country.Vanuatu"]}
        , { value: "Vatican City State (Holy See) [country=VA]", label: messages["country.Vatican City State (Holy See)"]}
        , { value: "Venezuela [country=VE]", label: messages["country.Venezuela"]}
        , { value: "Vietnam [country=VN]", label: messages["country.Vietnam"]}
        , { value: "Virgin Islands, British [country=VG]", label: messages["country.Virgin Islands, British"]}
        , { value: "Virgin Islands, U.S. [country=VI]", label: messages["country.Virgin Islands, U.S."]}
        , { value: "Wallis and Futuna Islands [country=WF]", label: messages["country.Wallis and Futuna Islands"]}
        , { value: "Western Sahara [country=EH]", label: messages["country.Western Sahara"]}
        , { value: "Yemen [country=YE]", label: messages["country.Yemen"]}
        , { value: "Yugoslavia [country=YU]", label: messages["country.Yugoslavia"]}
        , { value: "Zambia [country=ZM]", label: messages["country.Zambia"]}
        , { value: "Zimbabwe [country=ZW]", label: messages["country.Zimbabwe"]}
    ]
    , provinceComplaint : [
        { value:"Alberta [province=AB]", label: messages["province.Alberta"]}
        , { value:"British Columbia [province=BC]", label: messages["province.British Columbia"]}
        , { value:"Manitoba [province=MB]", label: messages["province.Manitoba"]}
        , { value:"New Brunswick [province=NB]", label: messages["province.New Brunswick"]}
        , { value:"Newfoundland and Labrador [province=NL]", label: messages["province.Newfoundland and Labrador"]}
        , { value:"Northwest Territories [province=NT]", label: messages["province.Northwest Territories"]}
        , { value:"Nova Scotia [province=NS]", label: messages["province.Nova Scotia"]}
        , { value:"Nunavut [province=NU]", label: messages["province.Nunavut"]}
        , { value:"Ontario [province=ON]", label: messages["province.Ontario"]}
        , { value:"Prince Edward Island [province=PE]", label: messages["province.Prince Edward Island"]}
        , { value:"Quebec [province=QC]", label: messages["province.Quebec"]}
        , { value:"Saskatchewan [province=SK]", label: messages["province.Saskatchewan"]}
        , { value:"Yukon [province=YT]", label: messages["province.Yukon"]}
    ]
    , stateComplaint : [
        { value:"United States Minor Outlying Islands [province=UM]", label: messages["state.United States Minor Outlying Islands"]}
        , { value:"Alabama [province=AL]", label: messages["state.Alabama"]}
        , { value:"Alaska [province=AK]", label: messages["state.Alaska"]}
        , { value:"Arizona [province=AZ]", label: messages["state.Arizona"]}
        , { value:"Arkansas [province=AR]", label: messages["state.Arkansas"]}
        , { value:"California [province=CA]", label: messages["state.California"]}
        , { value:"Colorado [province=CO]", label: messages["state.Colorado"]}
        , { value:"Connecticut [province=CT]", label: messages["state.Connecticut"]}
        , { value:"Delaware [province=DE]", label: messages["state.Delaware"]}
        , { value:"District of Columbia [province=DC]", label: messages["state.District of Columbia"]}
        , { value:"Florida [province=FL]", label: messages["state.Florida"]}
        , { value:"Georgia [province=GA]", label: messages["state.Georgia"]}
        , { value:"Hawaii [province=HI]", label: messages["state.Hawaii"]}
        , { value:"Idaho [province=ID]", label: messages["state.Idaho"]}
        , { value:"Illinois [province=IL]", label: messages["state.Illinois"]}
        , { value:"Indiana [province=IN]", label: messages["state.Indiana"]}
        , { value:"Iowa [province=IA]", label: messages["state.Iowa"]}
        , { value:"Kansas [province=KS]", label: messages["state.Kansas"]}
        , { value:"Kentucky [province=KY]", label: messages["state.Kentucky"]}
        , { value:"Louisiana [province=LA]", label: messages["state.Louisiana"]}
        , { value:"Maine [province=ME]", label: messages["state.Maine"]}
        , { value:"Maryland [province=MD]", label: messages["state.Maryland"]}
        , { value:"Massachusetts [province=MA]", label: messages["state.Massachusetts"]}
        , { value:"Michigan [province=MI]", label: messages["state.Michigan"]}
        , { value:"Minnesota [province=MN]", label: messages["state.Minnesota"]}
        , { value:"Mississippi [province=MS]", label: messages["state.Mississippi"]}
        , { value:"Missouri [province=MO]", label: messages["state.Missouri"]}
        , { value:"Montana [province=MT]", label: messages["state.Montana"]}
        , { value:"Nebraska [province=NE]", label: messages["state.Nebraska"]}
        , { value:"Nevada [province=NV]", label: messages["state.Nevada"]}
        , { value:"New Hampshire [province=NH]", label: messages["state.New Hampshire"]}
        , { value:"New Jersey [province=NJ]", label: messages["state.New Jersey"]}
        , { value:"New Mexico [province=NM]", label: messages["state.New Mexico"]}
        , { value:"New York [province=NY]", label: messages["state.New York"]}
        , { value:"North Carolina [province=NC]", label: messages["state.North Carolina"]}
        , { value:"North Dakota [province=ND]", label: messages["state.North Dakota"]}
        , { value:"Ohio [province=OH]", label: messages["state.Ohio"]}
        , { value:"Oklahoma [province=OK]", label: messages["state.Oklahoma"]}
        , { value:"Oregon [province=OR]", label: messages["state.Oregon"]}
        , { value:"Pennsylvania [province=PA]", label: messages["state.Pennsylvania"]}
        , { value:"Rhode Island [province=RI]", label: messages["state.Rhode Island"]}
        , { value:"South Carolina [province=SC]", label: messages["state.South Carolina"]}
        , { value:"South Dakota [province=SD]", label: messages["state.South Dakota"]}
        , { value:"Tennessee [province=TN]", label: messages["state.Tennessee"]}
        , { value:"Texas [province=TX]", label: messages["state.Texas"]}
        , { value:"Utah [province=UT]", label: messages["state.Utah"]}
        , { value:"Vermont [province=VT]", label: messages["state.Vermont"]}
        , { value:"Virginia [province=VA]", label: messages["state.Virginia"]}
        , { value:"Washington [province=WA]", label: messages["state.Washington"]}
        , { value:"West Virginia [province=WV]", label: messages["state.West Virginia"]}
        , { value:"Wisconsin [province=WI]", label: messages["state.Wisconsin"]}
        , { value:"Wyoming [province=WY]", label: messages["state.Wyoming"]}
    ]
    , orderCommunication : [
        { value:"third_party [order-comm-3=third_party]", label: messages["orderCommunication.thirdParty"]}
		, { value:"complainant [order-comm-3=complainant]", label: messages["orderCommunication.complainant"]}
		, { value:"both [order-comm-3=both]", label: messages["orderCommunication.both"]}
    ]
    , locationOfIncident : [
        { value:"Abercorn [location=3180]", label: messages["location.Abercorn"]}
        , { value:"Aden [location=7052]", label: messages["location.Aden"]}
        , { value:"Alberta South - Intelligence [location=7015]", label: messages["location.Alberta South - Intelligence"]}
        , { value:"Aldergrove [location=8174]", label: messages["location.Aldergrove"]}
        , { value:"Amherst [location=7510]", label: messages["location.Amherst"]}
        , { value:"Argentia [location=9210]", label: messages["location.Argentia"]}
        , { value:"Armstrong [location=3291]", label: messages["location.Armstrong"]}
        , { value:"Atlantic - Inland Investigations & Removals [location=2021]", label: messages["location.Atlantic - Inland Investigations & Removals"]}
        , { value:"Baie-Comeau [location=3550]", label: messages["location.Baie-Comeau"]}
        , { value:"Barrie [location=4590]", label: messages["location.Barrie"]}
        , { value:"Bathurst [location=2010]", label: messages["location.Bathurst"]}
        , { value:"Beaver Creek [location=8902]", label: messages["location.Beaver Creek"]}
        , { value:"Bedwell Harbour [location=8112]", label: messages["location.Bedwell Harbour"]}
        , { value:"Belleville [location=4020]", label: messages["location.Belleville"]}
        , { value:"Belleville Ferry Terminal [location=8115]", label: messages["location.Belleville Ferry Terminal"]}
        , { value:"Big Beaver [location=6011]", label: messages["location.Big Beaver"]}
        , { value:"Billy Bishop Toronto City Airport [location=4952]", label: messages["location.Billy Bishop Toronto City Airport"]}
        , { value:"Blackball (Coho) Ferry Terminal - Passenger [location=8116]", label: messages["location.Blackball (Coho) Ferry Terminal - Passenger"]}
        , { value:"Blackball (Coho) Ferry Terminal - Vehicle [location=8117]", label: messages["location.Blackball (Coho) Ferry Terminal - Vehicle"]}
        , { value:"Bloomfield [location=2280]", label: messages["location.Bloomfield"]}
        , { value:"Boissevain [location=5071]", label: messages["location.Boissevain"]}
        , { value:"Boundary Bay [location=8150]", label: messages["location.Boundary Bay"]}
        , { value:"Bracebridge [location=4600]", label: messages["location.Bracebridge"]}
        , { value:"Brampton [location=4800]", label: messages["location.Brampton"]}
        , { value:"Brantford [location=4040]", label: messages["location.Brantford"]}
        , { value:"Brockville [location=4050]", label: messages["location.Brockville"]}
        , { value:"Calgary - Commercial [location=7012]", label: messages["location.Calgary - Commercial"]}
        , { value:"Calgary - Criminal Investigations [location=1560]", label: messages["location.Calgary - Criminal Investigations"]}
        , { value:"Calgary - Inland Enforcement [location=1544]", label: messages["location.Calgary - Inland Enforcement"]}
        , { value:"Calgary International Airport - Traffic [location=7011]", label: messages["location.Calgary International Airport - Traffic"]}
        , { value:"Cambridge [location=4131]", label: messages["location.Cambridge"]}
        , { value:"Campobello [location=2250]", label: messages["location.Campobello"]}
        , { value:"Canada Place Cruise Ship Terminal [location=8096]", label: messages["location.Canada Place Cruise Ship Terminal"]}
        , { value:"CANPASS - NEXUS [location=9998]", label: messages["location.CANPASS - NEXUS"]}
        , { value:"Cap-aux-Meules [location=3630]", label: messages["location.Cap-aux-Meules"]}
        , { value:"Caraquet [location=2210]", label: messages["location.Caraquet"]}
        , { value:"Carievale [location=6024]", label: messages["location.Carievale"]}
        , { value:"Carson [location=8161]", label: messages["location.Carson"]}
        , { value:"Cartwright [location=5072]", label: messages["location.Cartwright"]}
        , { value:"Carway [location=7053]", label: messages["location.Carway"]}
        , { value:"Cascade [location=8162]", label: messages["location.Cascade"]}
        , { value:"CBSA College [location=1102]", label: messages["location.CBSA College"]}
        , { value:"Centreville [location=2150]", label: messages["location.Centreville"]}
        , { value:"Charlottetown [location=1010]", label: messages["location.Charlottetown"]}
        , { value:"Chartierville [location=3680]", label: messages["location.Chartierville"]}
        , { value:"Chatham [location=4060]", label: messages["location.Chatham"]}
        , { value:"Chicoutimi [location=3010]", label: messages["location.Chicoutimi"]}
        , { value:"Chief Mountain [location=7054]", label: messages["location.Chief Mountain"]}
        , { value:"Chopaka [location=8320]", label: messages["location.Chopaka"]}
        , { value:"Churchill [location=5042]", label: messages["location.Churchill"]}
        , { value:"Clair [location=2160]", label: messages["location.Clair"]}
        , { value:"Clarenceville [location=3380]", label: messages["location.Clarenceville"]}
        , { value:"Clarenville [location=9000]", label: messages["location.Clarenville"]}
        , { value:"Climax [location=6014]", label: messages["location.Climax"]}
        , { value:"Cobourg [location=4730]", label: messages["location.Cobourg"]}
        , { value:"Cold Lake C.F.B. [location=7025]", label: messages["location.Cold Lake C.F.B."]}
        , { value:"Collingwood [location=4080]", label: messages["location.Collingwood"]}
        , { value:"Comox [location=8300]", label: messages["location.Comox"]}
        , { value:"Corner Brook [location=9111]", label: messages["location.Corner Brook"]}
        , { value:"Cornwall - Commercial [location=4091]", label: messages["location.Cornwall - Commercial"]}
        , { value:"Cornwall - Intelligence & Enforcement [location=4093]", label: messages["location.Cornwall - Intelligence & Enforcement"]}
        , { value:"Cornwall - Traffic [location=4090]", label: messages["location.Cornwall - Traffic"]}
        , { value:"Coronach [location=6012]", label: messages["location.Coronach"]}
        , { value:"Cote de Liesse Warehouse [location=3980]", label: messages["location.Cote de Liesse Warehouse"]}
        , { value:"Coulter [location=5081]", label: messages["location.Coulter"]}
        , { value:"Courtenay [location=8290]", label: messages["location.Courtenay"]}
        , { value:"Coutts - Commercial [location=7051]", label: messages["location.Coutts - Commercial"]}
        , { value:"Coutts - Intelligence [location=7056]", label: messages["location.Coutts - Intelligence"]}
        , { value:"Coutts - Traffic [location=7050]", label: messages["location.Coutts - Traffic"]}
        , { value:"Covey Hill [location=3332]", label: messages["location.Covey Hill"]}
        , { value:"Cowansville [location=3560]", label: messages["location.Cowansville"]}
        , { value:"Cranbrook [location=8011]", label: messages["location.Cranbrook"]}
        , { value:"Crystal City [location=5091]", label: messages["location.Crystal City"]}
        , { value:"Dalhousie [location=2220]", label: messages["location.Dalhousie"]}
        , { value:"Dawson City [location=8909]", label: messages["location.Dawson City"]}
        , { value:"Dawson Creek [location=8420]", label: messages["location.Dawson Creek"]}
        , { value:"Deer Island Point [location=2080]", label: messages["location.Deer Island Point"]}
        , { value:"Del Bonita [location=7055]", label: messages["location.Del Bonita"]}
        , { value:"Dorval - Criminal Investigations [location=3005]", label: messages["location.Dorval - Criminal Investigations"]}
        , { value:"Douglas [location=8135]", label: messages["location.Douglas"]}
        , { value:"Drummondville [location=3030]", label: messages["location.Drummondville"]}
        , { value:"Dundee [location=3300]", label: messages["location.Dundee"]}
        , { value:"East Hereford [location=3620]", label: messages["location.East Hereford"]}
        , { value:"East Pinnacle [location=3690]", label: messages["location.East Pinnacle"]}
        , { value:"Edmonton - Commercial Operations [location=7021]", label: messages["location.Edmonton - Commercial Operations"]}
        , { value:"Edmonton - Criminal Investigations [location=1562]", label: messages["location.Edmonton - Criminal Investigations"]}
        , { value:"Edmonton - Intelligence [location=7026]", label: messages["location.Edmonton - Intelligence"]}
        , { value:"Edmonton International Airport - Traffic [location=7023]", label: messages["location.Edmonton International Airport - Traffic"]}
        , { value:"Edmundston [location=2130]", label: messages["location.Edmundston"]}
        , { value:"Edmundston - Enforcement & Intelligence [location=1111]", label: messages["location.Edmundston - Enforcement & Intelligence"]}
        , { value:"Emerson West Lynne - Commercial [location=5022]", label: messages["location.Emerson West Lynne - Commercial"]}
        , { value:"Emerson West Lynne - Traffic [location=5021]", label: messages["location.Emerson West Lynne - Traffic"]}
        , { value:"Estevan Highway [location=6102]", label: messages["location.Estevan Highway"]}
        , { value:"Flathead [location=8410]", label: messages["location.Flathead"]}
        , { value:"Forest City [location=2340]", label: messages["location.Forest City"]}
        , { value:"Fort Erie - Niagara Falls Integrated Risk Analysis Unit [location=4269]", label: messages["location.Fort Erie - Niagara Falls Integrated Risk Analysis Unit"]}
        , { value:"Fort Erie, Peace Bridge - Bus [location=4104]", label: messages["location.Fort Erie, Peace Bridge - Bus"]}
        , { value:"Fort Erie, Peace Bridge - Commercial [location=4102]", label: messages["location.Fort Erie, Peace Bridge - Commercial"]}
        , { value:"Fort Erie, Peace Bridge - Traffic/Bus [location=4101]", label: messages["location.Fort Erie, Peace Bridge - Traffic/Bus"]}
        , { value:"Fort Frances - Commercial & Traffic [location=4780]", label: messages["location.Fort Frances - Commercial & Traffic"]}
        , { value:"Fort Frances - Intelligence & Enforcement [location=1293]", label: messages["location.Fort Frances - Intelligence & Enforcement"]}
        , { value:"Fort McMurray Airport [location=7019]", label: messages["location.Fort McMurray Airport"]}
        , { value:"Fortune [location=9190]", label: messages["location.Fortune"]}
        , { value:"Fosterville [location=2350]", label: messages["location.Fosterville"]}
        , { value:"Four Falls [location=2050]", label: messages["location.Four Falls"]}
        , { value:"Franklin Centre [location=3240]", label: messages["location.Franklin Centre"]}
        , { value:"Fraser [location=8904]", label: messages["location.Fraser"]}
        , { value:"Fredericton [location=2040]", label: messages["location.Fredericton"]}
        , { value:"Fredericton - Criminal Investigations [location=1118]", label: messages["location.Fredericton - Criminal Investigations"]}
        , { value:"Fredericton - Enforcement & Intelligence [location=1112]", label: messages["location.Fredericton - Enforcement & Intelligence"]}
        , { value:"Frelighsburg [location=3320]", label: messages["location.Frelighsburg"]}
        , { value:"Gander International Airport [location=9121]", label: messages["location.Gander International Airport"]}
        , { value:"GaspÃ© [location=3040]", label: messages["location.GaspÃ©"]}
        , { value:"Gatineau - Investigations & Removals [location=3048]", label: messages["location.Gatineau - Investigations & Removals"]}
        , { value:"Gillespie Portage [location=2370]", label: messages["location.Gillespie Portage"]}
        , { value:"Glen Sutton [location=3710]", label: messages["location.Glen Sutton"]}
        , { value:"Goodlands [location=5082]", label: messages["location.Goodlands"]}
        , { value:"Goose Bay [location=9130]", label: messages["location.Goose Bay"]}
        , { value:"Granby [location=3050]", label: messages["location.Granby"]}
        , { value:"Grand Falls [location=2170]", label: messages["location.Grand Falls"]}
        , { value:"Grand Falls [location=9100]", label: messages["location.Grand Falls"]}
        , { value:"Grand Manan [location=2240]", label: messages["location.Grand Manan"]}
        , { value:"Greater Toronto Area - Inland Investigations & Removals [location=4255]", label: messages["location.Greater Toronto Area - Inland Investigations & Removals"]}
        , { value:"Greater Toronto Area Region - Program Services [location=1317]", label: messages["location.Greater Toronto Area Region - Program Services"]}
        , { value:"Gretna [location=5030]", label: messages["location.Gretna"]}
        , { value:"Guelph [location=4161]", label: messages["location.Guelph"]}
        , { value:"Halifax - Air Cargo [location=7591]", label: messages["location.Halifax - Air Cargo"]}
        , { value:"Halifax - Criminal Investigations [location=1110]", label: messages["location.Halifax - Criminal Investigations"]}
        , { value:"Halifax - Enforcement & Intelligence [location=1114]", label: messages["location.Halifax - Enforcement & Intelligence"]}
        , { value:"Halifax International Airport - Traffic [location=7593]", label: messages["location.Halifax International Airport - Traffic"]}
        , { value:"Halifax Marine Centre of Expertise [location=1139]", label: messages["location.Halifax Marine Centre of Expertise"]}
        , { value:"Halifax Marine Operations [location=7595]", label: messages["location.Halifax Marine Operations"]}
        , { value:"Halifax Marine Targeting Unit/C.E.T. [location=7690]", label: messages["location.Halifax Marine Targeting Unit/C.E.T."]}
        , { value:"Halton Hills [location=4830]", label: messages["location.Halton Hills"]}
        , { value:"Hamilton - Enforcement & Intelligence [location=1356]", label: messages["location.Hamilton - Enforcement & Intelligence"]}
        , { value:"Hamilton International Airport & Marine [location=4171]", label: messages["location.Hamilton International Airport & Marine"]}
        , { value:"Hamilton Main Sufferance Warehouse [location=4170]", label: messages["location.Hamilton Main Sufferance Warehouse"]}
        , { value:"Hanover [location=4840]", label: messages["location.Hanover"]}
        , { value:"Harbour Grace [location=9220]", label: messages["location.Harbour Grace"]}
        , { value:"Hemmingford - Traffic [location=3331]", label: messages["location.Hemmingford - Traffic"]}
        , { value:"Herdman [location=3020]", label: messages["location.Herdman"]}
        , { value:"Hereford Road [location=3640]", label: messages["location.Hereford Road"]}
        , { value:"Highwater [location=3340]", label: messages["location.Highwater"]}
        , { value:"Huntingdon - Commercial [location=8171]", label: messages["location.Huntingdon - Commercial"]}
        , { value:"Huntingdon - Traffic [location=8173]", label: messages["location.Huntingdon - Traffic"]}
        , { value:"International Ferry Terminal [location=4955]", label: messages["location.International Ferry Terminal"]}
        , { value:"Interport Sufferance Warehouse [location=4960]", label: messages["location.Interport Sufferance Warehouse"]}
        , { value:"Inuvik [location=5046]", label: messages["location.Inuvik"]}
        , { value:"Iqaluit [location=5047]", label: messages["location.Iqaluit"]}
        , { value:"Jamieson's Line [location=3720]", label: messages["location.Jamieson's Line"]}
        , { value:"Jean Lesage International Airport [location=3123]", label: messages["location.Jean Lesage International Airport"]}
        , { value:"Joliette [location=3420]", label: messages["location.Joliette"]}
        , { value:"Kamloops [location=8140]", label: messages["location.Kamloops"]}
        , { value:"Kelowna [location=8310]", label: messages["location.Kelowna"]}
        , { value:"Kenora [location=4900]", label: messages["location.Kenora"]}
        , { value:"Kentville [location=7600]", label: messages["location.Kentville"]}
        , { value:"Kingsgate - Commercial [location=8181]", label: messages["location.Kingsgate - Commercial"]}
        , { value:"Kingsgate - Traffic [location=8182]", label: messages["location.Kingsgate - Traffic"]}
        , { value:"Kingston [location=4200]", label: messages["location.Kingston"]}
        , { value:"Kitchener - Inland Investigations & Removals [location=4125]", label: messages["location.Kitchener - Inland Investigations & Removals"]}
        , { value:"Kitchener Airport/Sufferance Warehouse [location=4212]", label: messages["location.Kitchener Airport/Sufferance Warehouse"]}
        , { value:"Kitchener Longroom [location=4211]", label: messages["location.Kitchener Longroom"]}
        , { value:"Kitimat [location=8270]", label: messages["location.Kitimat"]}
        , { value:"Lachute [location=3430]", label: messages["location.Lachute"]}
        , { value:"Lacolle Regional Processing Center or Montreal Urban Processing Center [location=2554]", label: messages["location.Lacolle Regional Processing Center or Montreal Urban Processing Center"]}
        , { value:"Lacolle Route 15 - Commercial [location=3515]", label: messages["location.Lacolle Route 15 - Commercial"]}
        , { value:"Lacolle Route 15 - Traffic [location=3513]", label: messages["location.Lacolle Route 15 - Traffic"]}
        , { value:"Lacolle Route 221 - Traffic [location=3512]", label: messages["location.Lacolle Route 221 - Traffic"]}
        , { value:"Lacolle Route 223 - Traffic [location=3511]", label: messages["location.Lacolle Route 223 - Traffic"]}
        , { value:"Lansdowne - Commercial [location=4561]", label: messages["location.Lansdowne - Commercial"]}
        , { value:"Lansdowne - Intelligence & Enforcement [location=4562]", label: messages["location.Lansdowne - Intelligence & Enforcement"]}
        , { value:"Lansdowne - Traffic [location=4560]", label: messages["location.Lansdowne - Traffic"]}
        , { value:"Laval - Immigration Holding Centre [location=3900]", label: messages["location.Laval - Immigration Holding Centre"]}
        , { value:"Lena [location=5073]", label: messages["location.Lena"]}
        , { value:"Lethbridge [location=7031]", label: messages["location.Lethbridge"]}
        , { value:"Lindsay [location=4770]", label: messages["location.Lindsay"]}
        , { value:"Little Gold Creek [location=8905]", label: messages["location.Little Gold Creek"]}
        , { value:"Liverpool [location=7610]", label: messages["location.Liverpool"]}
        , { value:"London - Criminal Investigations [location=1410]", label: messages["location.London - Criminal Investigations"]}
        , { value:"London - Inland Enforcement [location=4041]", label: messages["location.London - Inland Enforcement"]}
        , { value:"London - Intelligence [location=1412]", label: messages["location.London - Intelligence"]}
        , { value:"London Airport [location=4233]", label: messages["location.London Airport"]}
        , { value:"London Sufferance Warehouse [location=4234]", label: messages["location.London Sufferance Warehouse"]}
        , { value:"Lunenburg [location=7630]", label: messages["location.Lunenburg"]}
        , { value:"Lyleton [location=5083]", label: messages["location.Lyleton"]}
        , { value:"Macdonald-Cartier International Airport [location=4312]", label: messages["location.Macdonald-Cartier International Airport"]}
        , { value:"Manitoba - Intelligence [location=5041]", label: messages["location.Manitoba - Intelligence"]}
        , { value:"Midcontinent Truck Terminal [location=4980]", label: messages["location.Midcontinent Truck Terminal"]}
        , { value:"Midland [location=4240]", label: messages["location.Midland"]}
        , { value:"Midway [location=8330]", label: messages["location.Midway"]}
        , { value:"Milltown [location=2300]", label: messages["location.Milltown"]}
        , { value:"Mirabel International Airport - Cargo [location=3992]", label: messages["location.Mirabel International Airport - Cargo"]}
        , { value:"Mirabel International Airport - Traffic [location=3991]", label: messages["location.Mirabel International Airport - Traffic"]}
        , { value:"Miramichi (Newcastle) [location=2070]", label: messages["location.Miramichi (Newcastle)"]}
        , { value:"Monchy [location=6015]", label: messages["location.Monchy"]}
        , { value:"Moncton [location=2060]", label: messages["location.Moncton"]}
        , { value:"Moncton - Enforcement & Intelligence [location=2061]", label: messages["location.Moncton - Enforcement & Intelligence"]}
        , { value:"Montmagny [location=3440]", label: messages["location.Montmagny"]}
        , { value:"Montreal - Criminal Investigations [location=1210]", label: messages["location.Montreal - Criminal Investigations"]}
        , { value:"Montreal - Inland Enforcement - Investigations [location=3025]", label: messages["location.Montreal - Inland Enforcement - Investigations"]}
        , { value:"Montreal - Inland Enforcement - Removals [location=3033]", label: messages["location.Montreal - Inland Enforcement - Removals"]}
        , { value:"Montreal - Intelligence [location=1213]", label: messages["location.Montreal - Intelligence"]}
        , { value:"Montreal CBSA Mail Centre (LÃ©o-Blanchette) [location=3952]", label: messages["location.Montreal CBSA Mail Centre (LÃ©o-Blanchette)"]}
        , { value:"Montreal Longroom [location=3951]", label: messages["location.Montreal Longroom"]}
        , { value:"Montreal Marine and Rail Services [location=3957]", label: messages["location.Montreal Marine and Rail Services"]}
        , { value:"Moose Jaw [location=6010]", label: messages["location.Moose Jaw"]}
        , { value:"Morses Line [location=3740]", label: messages["location.Morses Line"]}
        , { value:"Nanaimo [location=8040]", label: messages["location.Nanaimo"]}
        , { value:"Nelway [location=8340]", label: messages["location.Nelway"]}
        , { value:"New Glasgow [location=7650]", label: messages["location.New Glasgow"]}
        , { value:"Newmarket [location=4680]", label: messages["location.Newmarket"]}
        , { value:"Niagara - Criminal Investigations [location=1359]", label: messages["location.Niagara - Criminal Investigations"]}
        , { value:"Niagara - Inland Enforcement [location=4124]", label: messages["location.Niagara - Inland Enforcement"]}
        , { value:"Niagara - Intelligence [location=1375]", label: messages["location.Niagara - Intelligence"]}
        , { value:"Niagara Falls Customs Processing Centre [location=4270]", label: messages["location.Niagara Falls Customs Processing Centre"]}
        , { value:"Niagara Falls Longroom [location=4274]", label: messages["location.Niagara Falls Longroom"]}
        , { value:"Niagara Falls VIA Passenger Rail [location=4277]", label: messages["location.Niagara Falls VIA Passenger Rail"]}
        , { value:"North Bay [location=4280]", label: messages["location.North Bay"]}
        , { value:"North Portal - Commercial [location=6025]", label: messages["location.North Portal - Commercial"]}
        , { value:"North Portal - Traffic [location=6021]", label: messages["location.North Portal - Traffic"]}
        , { value:"Northern Ontario - Inland Investigations & Removals [location=4353]", label: messages["location.Northern Ontario - Inland Investigations & Removals"]}
        , { value:"Northgate [location=6023]", label: messages["location.Northgate"]}
        , { value:"Noyan [location=3370]", label: messages["location.Noyan"]}
        , { value:"Oakville [location=4760]", label: messages["location.Oakville"]}
        , { value:"Orangeville [location=4870]", label: messages["location.Orangeville"]}
        , { value:"Orillia [location=4290]", label: messages["location.Orillia"]}
        , { value:"Oshawa [location=4300]", label: messages["location.Oshawa"]}
        , { value:"Osoyoos - Commercial [location=8192]", label: messages["location.Osoyoos - Commercial"]}
        , { value:"Osoyoos - Traffic [location=8191]", label: messages["location.Osoyoos - Traffic"]}
        , { value:"Ottawa - Criminal Investigations [location=1260]", label: messages["location.Ottawa - Criminal Investigations"]}
        , { value:"Ottawa - Intelligence & Enforcement [location=1263]", label: messages["location.Ottawa - Intelligence & Enforcement"]}
        , { value:"Ottawa Cargo Services [location=4313]", label: messages["location.Ottawa Cargo Services"]}
        , { value:"Ottawa Longroom [location=4311]", label: messages["location.Ottawa Longroom"]}
        , { value:"Oungre [location=6100]", label: messages["location.Oungre"]}
        , { value:"Owen Sound [location=4820]", label: messages["location.Owen Sound"]}
        , { value:"Pacific Highway - Commercial [location=8131]", label: messages["location.Pacific Highway - Commercial"]}
        , { value:"Pacific Highway - Traffic [location=8132]", label: messages["location.Pacific Highway - Traffic"]}
        , { value:"Pacific Region Enforcement Centre [location=1656]", label: messages["location.Pacific Region Enforcement Centre"]}
        , { value:"Paterson [location=8360]", label: messages["location.Paterson"]}
        , { value:"Pearson International Airport - Air Cargo [location=4973]", label: messages["location.Pearson International Airport - Air Cargo"]}
        , { value:"Pearson International Airport - Terminal 1 [location=4971]", label: messages["location.Pearson International Airport - Terminal 1"]}
        , { value:"Pearson International Airport - Terminal 3 [location=4974]", label: messages["location.Pearson International Airport - Terminal 3"]}
        , { value:"Pelee Island [location=4523]", label: messages["location.Pelee Island"]}
        , { value:"Penticton [location=8070]", label: messages["location.Penticton"]}
        , { value:"Penticton - Intelligence [location=8071]", label: messages["location.Penticton - Intelligence"]}
        , { value:"Perth-Andover [location=2140]", label: messages["location.Perth-Andover"]}
        , { value:"Peterborough [location=4000]", label: messages["location.Peterborough"]}
        , { value:"Pigeon River [location=4751]", label: messages["location.Pigeon River"]}
        , { value:"Piney [location=5051]", label: messages["location.Piney"]}
        , { value:"Pleasant Camp [location=8906]", label: messages["location.Pleasant Camp"]}
        , { value:"Pohenegamook [location=3310]", label: messages["location.Pohenegamook"]}
        , { value:"Port Alberni [location=8250]", label: messages["location.Port Alberni"]}
        , { value:"Port Cartier [location=3612]", label: messages["location.Port Cartier"]}
        , { value:"Port Colborne [location=4710]", label: messages["location.Port Colborne"]}
        , { value:"Powell River [location=8260]", label: messages["location.Powell River"]}
        , { value:"Prairie  - Inland Investigations & Removals [location=5033]", label: messages["location.Prairie  - Inland Investigations & Removals"]}
        , { value:"Prairie - Trade Administration Services [location=1527]", label: messages["location.Prairie - Trade Administration Services"]}
        , { value:"Prescott - Commercial [location=4391]", label: messages["location.Prescott - Commercial"]}
        , { value:"Prescott - Traffic [location=4390]", label: messages["location.Prescott - Traffic"]}
        , { value:"Prince Albert [location=6030]", label: messages["location.Prince Albert"]}
        , { value:"Prince George [location=8201]", label: messages["location.Prince George"]}
        , { value:"Prince Rupert [location=8080]", label: messages["location.Prince Rupert"]}
        , { value:"Quai Richelieu [location=3514]", label: messages["location.Quai Richelieu"]}
        , { value:"Quebec - Criminal Investigations [location=1159]", label: messages["location.Quebec - Criminal Investigations"]}
        , { value:"Quebec - Intelligence [location=1164]", label: messages["location.Quebec - Intelligence"]}
        , { value:"Quebec - Investigations & Removals [location=3047]", label: messages["location.Quebec - Investigations & Removals"]}
        , { value:"QuÃ©bec City Cruise Ship Terminal [location=3122]", label: messages["location.QuÃ©bec City Cruise Ship Terminal"]}
        , { value:"Quebec Main [location=3121]", label: messages["location.Quebec Main"]}
        , { value:"Queenston Bridge - Commercial [location=4276]", label: messages["location.Queenston Bridge - Commercial"]}
        , { value:"Queenston Bridge - Traffic [location=4273]", label: messages["location.Queenston Bridge - Traffic"]}
        , { value:"Rainbow Bridge - Traffic [location=4272]", label: messages["location.Rainbow Bridge - Traffic"]}
        , { value:"Rainy River [location=4880]", label: messages["location.Rainy River"]}
        , { value:"Regina - Criminal Investigations [location=1599]", label: messages["location.Regina - Criminal Investigations"]}
        , { value:"Regina - Intelligence [location=1515]", label: messages["location.Regina - Intelligence"]}
        , { value:"Regina Airport [location=6042]", label: messages["location.Regina Airport"]}
        , { value:"Regina Longroom [location=6043]", label: messages["location.Regina Longroom"]}
        , { value:"Regway [location=6070]", label: messages["location.Regway"]}
        , { value:"Richmond [location=3450]", label: messages["location.Richmond"]}
        , { value:"Richmond - Intelligence [location=8213]", label: messages["location.Richmond - Intelligence"]}
        , { value:"Rimouski [location=3130]", label: messages["location.Rimouski"]}
        , { value:"River de Chute [location=2190]", label: messages["location.River de Chute"]}
        , { value:"RiviÃ¨re-du-Loup [location=3400]", label: messages["location.RiviÃ¨re-du-Loup"]}
        , { value:"Rock Island - Intelligence [location=1116]", label: messages["location.Rock Island - Intelligence"]}
        , { value:"Roosville [location=8020]", label: messages["location.Roosville"]}
        , { value:"Rouyn-Noranda [location=3490]", label: messages["location.Rouyn-Noranda"]}
        , { value:"Rykerts [location=8370]", label: messages["location.Rykerts"]}
        , { value:"Saint Jean [location=3210]", label: messages["location.Saint Jean"]}
        , { value:"Saint John [location=2100]", label: messages["location.Saint John"]}
        , { value:"Saint John, NB - Enforcement & Intelligence [location=1117]", label: messages["location.Saint John, NB - Enforcement & Intelligence"]}
        , { value:"Sand Point Lake - Traffic & Commercial [location=4115]", label: messages["location.Sand Point Lake - Traffic & Commercial"]}
        , { value:"Sarnia - Criminal Investigations [location=4043]", label: messages["location.Sarnia - Criminal Investigations"]}
        , { value:"Sarnia - Inland Enforcement [location=4042]", label: messages["location.Sarnia - Inland Enforcement"]}
        , { value:"Sarnia - Intelligence [location=1413]", label: messages["location.Sarnia - Intelligence"]}
        , { value:"Sarnia, Blue Water Bridge - Commercial [location=4403]", label: messages["location.Sarnia, Blue Water Bridge - Commercial"]}
        , { value:"Sarnia, Blue Water Bridge - Traffic [location=4401]", label: messages["location.Sarnia, Blue Water Bridge - Traffic"]}
        , { value:"Saskatchewan District Office [location=1535]", label: messages["location.Saskatchewan District Office"]}
        , { value:"Saskatoon - Airport/Rail/Longroom [location=6050]", label: messages["location.Saskatoon - Airport/Rail/Longroom"]}
        , { value:"Sault Ste. Marie - Commercial [location=4411]", label: messages["location.Sault Ste. Marie - Commercial"]}
        , { value:"Sault Ste. Marie - Intelligence & Enforcement [location=4412]", label: messages["location.Sault Ste. Marie - Intelligence & Enforcement"]}
        , { value:"Sault Ste. Marie - Traffic [location=4410]", label: messages["location.Sault Ste. Marie - Traffic"]}
        , { value:"Sept-Iles [location=3611]", label: messages["location.Sept-Iles"]}
        , { value:"Shawinigan [location=3150]", label: messages["location.Shawinigan"]}
        , { value:"Shelburne [location=7700]", label: messages["location.Shelburne"]}
        , { value:"Sherbrooke [location=3160]", label: messages["location.Sherbrooke"]}
        , { value:"Sherbrooke - Criminal Investigations [location=1168]", label: messages["location.Sherbrooke - Criminal Investigations"]}
        , { value:"Sherbrooke - Intelligence [location=1162]", label: messages["location.Sherbrooke - Intelligence"]}
        , { value:"Sherbrooke - Investigations & Removals [location=3046]", label: messages["location.Sherbrooke - Investigations & Removals"]}
        , { value:"Sidney Marine [location=8380]", label: messages["location.Sidney Marine"]}
        , { value:"Simcoe [location=4420]", label: messages["location.Simcoe"]}
        , { value:"Snowflake [location=5092]", label: messages["location.Snowflake"]}
        , { value:"Sombra [location=4650]", label: messages["location.Sombra"]}
        , { value:"Sorel [location=3170]", label: messages["location.Sorel"]}
        , { value:"South Junction [location=5053]", label: messages["location.South Junction"]}
        , { value:"Southern Ontario Regional Office [location=1300]", label: messages["location.Southern Ontario Regional Office"]}
        , { value:"Sprague [location=5052]", label: messages["location.Sprague"]}
        , { value:"St. Andrews [location=2090]", label: messages["location.St. Andrews"]}
        , { value:"St. Armand - Commercial [location=3280]", label: messages["location.St. Armand - Commercial"]}
        , { value:"St. Armand - Intelligence [location=1173]", label: messages["location.St. Armand - Intelligence"]}
        , { value:"St. Armand - Traffic [location=3281]", label: messages["location.St. Armand - Traffic"]}
        , { value:"St. Catharines [location=4450]", label: messages["location.St. Catharines"]}
        , { value:"St. Catharines - Criminal Investigations [location=1429]", label: messages["location.St. Catharines - Criminal Investigations"]}
        , { value:"St. Croix [location=2310]", label: messages["location.St. Croix"]}
        , { value:"St. Jerome [location=3460]", label: messages["location.St. Jerome"]}
        , { value:"St. John's Airport & Marine [location=9141]", label: messages["location.St. John's Airport & Marine"]}
        , { value:"St. John's, NFLD - Criminal Investigations [location=2041]", label: messages["location.St. John's, NFLD - Criminal Investigations"]}
        , { value:"St. John's, NFLD - Enforcement & Intelligence [location=1113]", label: messages["location.St. John's, NFLD - Enforcement & Intelligence"]}
        , { value:"St. Leonard [location=2180]", label: messages["location.St. Leonard"]}
        , { value:"St. Stephen - 3rd Bridge - Commercial [location=2115]", label: messages["location.St. Stephen - 3rd Bridge - Commercial"]}
        , { value:"St. Stephen - 3rd Bridge - Traffic [location=2114]", label: messages["location.St. Stephen - 3rd Bridge - Traffic"]}
        , { value:"St. Stephen - Traffic [location=2110]", label: messages["location.St. Stephen - Traffic"]}
        , { value:"St. Thomas [location=4461]", label: messages["location.St. Thomas"]}
        , { value:"Stanhope [location=3540]", label: messages["location.Stanhope"]}
        , { value:"Stanstead - Route 143 [location=3142]", label: messages["location.Stanstead - Route 143"]}
        , { value:"Stanstead - Route 247 (Beebe) [location=3670]", label: messages["location.Stanstead - Route 247 (Beebe)"]}
        , { value:"Stanstead - Route 55 [location=3141]", label: messages["location.Stanstead - Route 55"]}
        , { value:"Ste-AurÃ©lie [location=3293]", label: messages["location.Ste-AurÃ©lie"]}
        , { value:"Stephenville [location=9180]", label: messages["location.Stephenville"]}
        , { value:"Stewart [location=8081]", label: messages["location.Stewart"]}
        , { value:"St-Hyacinthe [location=3200]", label: messages["location.St-Hyacinthe"]}
        , { value:"St-Just-de-BreteniÃ¨res [location=3360]", label: messages["location.St-Just-de-BreteniÃ¨res"]}
        , { value:"St-Pamphile [location=3750]", label: messages["location.St-Pamphile"]}
        , { value:"Stratford [location=4431]", label: messages["location.Stratford"]}
        , { value:"Sudbury [location=4440]", label: messages["location.Sudbury"]}
        , { value:"Sudbury - Criminal Investigations [location=1261]", label: messages["location.Sudbury - Criminal Investigations"]}
        , { value:"Summerside [location=1020]", label: messages["location.Summerside"]}
        , { value:"Surrey - Intelligence [location=8214]", label: messages["location.Surrey - Intelligence"]}
        , { value:"Sydney/Port Hawkesbury [location=7719]", label: messages["location.Sydney/Port Hawkesbury"]}
        , { value:"Thetford Mines [location=3470]", label: messages["location.Thetford Mines"]}
        , { value:"Thunder Bay [location=4750]", label: messages["location.Thunder Bay"]}
        , { value:"Thunder Bay - Intelligence & Enforcement [location=1269]", label: messages["location.Thunder Bay - Intelligence & Enforcement"]}
        , { value:"Tillsonburg [location=4470]", label: messages["location.Tillsonburg"]}
        , { value:"Timmins [location=4670]", label: messages["location.Timmins"]}
        , { value:"Tolstoi [location=5024]", label: messages["location.Tolstoi"]}
        , { value:"Toronto - Criminal Investigations [location=1310]", label: messages["location.Toronto - Criminal Investigations"]}
        , { value:"Toronto - Intelligence [location=1314]", label: messages["location.Toronto - Intelligence"]}
        , { value:"Toronto CBSA Mail Centre [location=4953]", label: messages["location.Toronto CBSA Mail Centre"]}
        , { value:"Toronto Main Longroom [location=4951]", label: messages["location.Toronto Main Longroom"]}
        , { value:"Toronto Sufferance Truck Terminal (Kennedy) Inc [location=4990]", label: messages["location.Toronto Sufferance Truck Terminal (Kennedy) Inc"]}
        , { value:"Torquay [location=6103]", label: messages["location.Torquay"]}
        , { value:"Trenton Airport [location=4490]", label: messages["location.Trenton Airport"]}
        , { value:"Trois-RiviÃ¨res [location=3220]", label: messages["location.Trois-RiviÃ¨res"]}
        , { value:"Trout River [location=3520]", label: messages["location.Trout River"]}
        , { value:"Trudeau Airport - Intelligence [location=1230]", label: messages["location.Trudeau Airport - Intelligence"]}
        , { value:"Trudeau International Airport - Cargo [location=3962]", label: messages["location.Trudeau International Airport - Cargo"]}
        , { value:"Trudeau International Airport - Traffic [location=3961]", label: messages["location.Trudeau International Airport - Traffic"]}
        , { value:"Truro [location=7720]", label: messages["location.Truro"]}
        , { value:"Tuktoyaktuk [location=5043]", label: messages["location.Tuktoyaktuk"]}
        , { value:"Val-d'Or [location=3500]", label: messages["location.Val-d'Or"]}
        , { value:"Valleyfield [location=3230]", label: messages["location.Valleyfield"]}
        , { value:"Vancouver - Criminal Investigations [location=1610]", label: messages["location.Vancouver - Criminal Investigations"]}
        , { value:"Vancouver - Intelligence [location=1613]", label: messages["location.Vancouver - Intelligence"]}
        , { value:"Vancouver Canadian National Train Terminal [location=8090]", label: messages["location.Vancouver Canadian National Train Terminal"]}
        , { value:"Vancouver Commercial Operations - East [location=8102]", label: messages["location.Vancouver Commercial Operations - East"]}
        , { value:"Vancouver Commercial Operations - West [location=8101]", label: messages["location.Vancouver Commercial Operations - West"]}
        , { value:"Vancouver Container Examination Facility [location=8094]", label: messages["location.Vancouver Container Examination Facility"]}
        , { value:"Vancouver International Airport - Cargo [location=8211]", label: messages["location.Vancouver International Airport - Cargo"]}
        , { value:"Vancouver International Airport - Traffic [location=8212]", label: messages["location.Vancouver International Airport - Traffic"]}
        , { value:"Vancouver International Mail Centre [location=8092]", label: messages["location.Vancouver International Mail Centre"]}
        , { value:"Vancouver Marine Operations [location=8093]", label: messages["location.Vancouver Marine Operations"]}
        , { value:"Vancouver Marine Vessel Operations [location=8095]", label: messages["location.Vancouver Marine Vessel Operations"]}
        , { value:"Vernon [location=8230]", label: messages["location.Vernon"]}
        , { value:"VIA - Airside and Special Enforcement Operations [location=8012]", label: messages["location.VIA - Airside and Special Enforcement Operations"]}
        , { value:"Victoria International Airport/Marine [location=8111]", label: messages["location.Victoria International Airport/Marine"]}
        , { value:"Victoria Longroom/Marine [location=8113]", label: messages["location.Victoria Longroom/Marine"]}
        , { value:"Victoriaville [location=3270]", label: messages["location.Victoriaville"]}
        , { value:"Wallaceburg [location=4500]", label: messages["location.Wallaceburg"]}
        , { value:"Walpole Island [location=4651]", label: messages["location.Walpole Island"]}
        , { value:"Waneta [location=8400]", label: messages["location.Waneta"]}
        , { value:"Washington State Ferries Terminal [location=8118]", label: messages["location.Washington State Ferries Terminal"]}
        , { value:"Welland [location=4510]", label: messages["location.Welland"]}
        , { value:"West Poplar River [location=6013]", label: messages["location.West Poplar River"]}
        , { value:"Whirlpool Bridge - Commercial [location=4275]", label: messages["location.Whirlpool Bridge - Commercial"]}
        , { value:"Whirlpool Bridge - Traffic [location=4271]", label: messages["location.Whirlpool Bridge - Traffic"]}
        , { value:"Whitehorse [location=8901]", label: messages["location.Whitehorse"]}
        , { value:"Wild Horse [location=7032]", label: messages["location.Wild Horse"]}
        , { value:"Willow Creek [location=7033]", label: messages["location.Willow Creek"]}
        , { value:"Windsor - Criminal Investigations [location=1309]", label: messages["location.Windsor - Criminal Investigations"]}
        , { value:"Windsor - Inland Enforcement [location=1389]", label: messages["location.Windsor - Inland Enforcement"]}
        , { value:"Windsor - Intelligence [location=1464]", label: messages["location.Windsor - Intelligence"]}
        , { value:"Windsor International Airport [location=4516]", label: messages["location.Windsor International Airport"]}
        , { value:"Windsor, Ambassador Bridge - Commercial [location=4530]", label: messages["location.Windsor, Ambassador Bridge - Commercial"]}
        , { value:"Windsor, Ambassador Bridge - Traffic [location=4531]", label: messages["location.Windsor, Ambassador Bridge - Traffic"]}
        , { value:"Windsor, D&C Tunnel - Traffic [location=4521]", label: messages["location.Windsor, D&C Tunnel - Traffic"]}
        , { value:"Windsor, Tunnel - Commercial [location=4522]", label: messages["location.Windsor, Tunnel - Commercial"]}
        , { value:"Windsor/St Clair Queen's Warehouse [location=1485]", label: messages["location.Windsor/St Clair Queen's Warehouse"]}
        , { value:"Windygates [location=5093]", label: messages["location.Windygates"]}
        , { value:"Winkler [location=5031]", label: messages["location.Winkler"]}
        , { value:"Winnipeg - Criminal Investigations [location=1510]", label: messages["location.Winnipeg - Criminal Investigations"]}
        , { value:"Winnipeg James Armstrong Richardson International Airport - Commercial [location=5101]", label: messages["location.Winnipeg James Armstrong Richardson International Airport - Commercial"]}
        , { value:"Winnipeg James Armstrong Richardson International Airport - Traffic [location=5102]", label: messages["location.Winnipeg James Armstrong Richardson International Airport - Traffic"]}
        , { value:"Winnipeg Land Main [location=5044]", label: messages["location.Winnipeg Land Main"]}
        , { value:"Winnipeg Warehouse [location=1530]", label: messages["location.Winnipeg Warehouse"]}
        , { value:"Woburn [location=3090]", label: messages["location.Woburn"]}
        , { value:"Woodstock [location=4920]", label: messages["location.Woodstock"]}
        , { value:"Woodstock - Commercial [location=2121]", label: messages["location.Woodstock - Commercial"]}
        , { value:"Woodstock - Enforcement & Intelligence [location=2123]", label: messages["location.Woodstock - Enforcement & Intelligence"]}
        , { value:"Woodstock - Traffic [location=2120]", label: messages["location.Woodstock - Traffic"]}
        , { value:"Yarmouth/Yarmouth Ferry Terminal [location=7750]", label: messages["location.Yarmouth/Yarmouth Ferry Terminal"]}
        , { value:"Yellowknife [location=5048]", label: messages["location.Yellowknife"]}
        , { value:"Other [9999]", label: messages["location.Other"]}
    ]
    , locationType : [
        { value:"Land Crossing [location-type=land_crossing]", label: messages["locationType.Land crossing"]}
        , { value:"Maritime Port of Entry [location-type=maritime_port]", label: messages["locationType.Maritime Port of Entry"]}
        , { value:"Airport [location-type=airport]", label: messages["locationType.Airport"]}
        , { value:"Detention Centre [location-type=detention_centre]", label: messages["locationType.Detention Centre"]}
        , { value:"Mail/Shipping Facility [location-type=mail_shipping_facility]", label: messages["locationType.Mail/Shipping Facility"]}
        , { value:"CBSA Office [location-type=cbsa_office]", label: messages["locationType.CBSA Office"]}
        , { value:"Other [location-type=other]", label: messages["locationType.Other"]}
    ]
    , referenceTypes : [
        { value:"Tracking/form/cargo number [reference-number=tracking_form_cargo_number]", label: messages["reference.Tracking/form/cargo number"]}
        , { value:"Container number [reference-number=container_number]", label: messages["reference.Container number"]}
        , { value:"Transaction number [reference-number=transaction_number]", label: messages["reference.Transaction number"]}
        , { value:"Cargo control number [reference-number=cargo_control_number]", label: messages["reference.Cargo control number"]}
        , { value:"Cargo control number (air) [reference-number=cargo_control_number_air]", label: messages["reference.Cargo control number (air)"]}
        , { value:"Postal tracking number [reference-number=postal_tracking_number]", label: messages["reference.Postal tracking number"]}
        , { value:"Postal tracking number (commercial) [reference-number=postal_tracking_number_commercial]", label: messages["reference.Postal tracking number (commercial)"]}
        , { value:"Postal tracking number (international) [reference-number=postal_tracking_number_international]", label: messages["reference.Postal tracking number (international)"]}
        , { value:"Airway bill number [reference-number=airway_bill_number]", label: messages["reference.Airway bill number"]}
        , { value:"Importer number [reference-number=importer_number]", label: messages["reference.Importer number"]}
        , { value:"BSF241 general receipt number [reference-number=BSF241]", label: messages["reference.BSF241 general receipt number"]}
        , { value:"BSF715 casual goods accounting document [reference-number=BSF715]", label: messages["reference.BSF715 casual goods accounting document"]}
        , { value:"Seizure receipt [reference-number=seizure_receipt]", label: messages["reference.Seizure receipt"]}
        , { value:"Unique client identifier (UCI) number [reference-number=uci]", label: messages["reference.Unique client identifier (UCI) number"]}
    ]
    , typeOfEvidence: [
        {value:"Video Recordings [type-of-evidence=video_recordings]", label:messages["video-recordings"]}
        , {value:"Audio Recordings [type-of-evidence=audio_recordings]", label:messages["audio-recordings"]}
        , {value:"Images [type-of-evidence=images]", label:messages["images"]}
        , {value:"Documents [type-of-evidence=documents]", label:messages["documents"]}
        , {value:"Other [type-of-evidence=other]", label:messages["other"]}
    ]

    //Use with above referenceTypes to grab the appropriate placeholder, keys must match referenceTypes' values
    , referenceNumberPlaceholders : {
        "Tracking/form/cargo number [reference-number=tracking_form_cargo_number]": "ex. XXXX00000000000"
        ,"Container number [reference-number=container_number]": "ex. ABCD 1234567"
        ,"Transaction number [reference-number=transaction_number]": "ex. 00000000000000"
        ,"Cargo control number [reference-number=cargo_control_number]": "ex. XXXXXXXXXXXXXXXXXXXXXXXXX"
        ,"Cargo control number (air) [reference-number=cargo_control_number_air]": "ex. XXX-XXXXXXXXXXXXXXXXXXXXX"
        ,"Postal tracking number [reference-number=postal_tracking_number]": "ex. 0000 0000 0000 0000"
        ,"Postal tracking number (commercial) [reference-number=postal_tracking_number_commercial]": "ex. AA 000 000 000 AA"
        ,"Postal tracking number (international) [reference-number=postal_tracking_number_international]": "ex. CD123456789XX"
        ,"Airway bill number [reference-number=airway_bill_number]": "ex. 000-00000000"
        ,"Importer number [reference-number=importer_number]": "ex. 123456789RM0001"
        ,"BSF241 general receipt number [reference-number=BSF241]": "ex. B000000"
        ,"BSF715 casual goods accounting document [reference-number=BSF715]": "ex. AB123456789 T"
        ,"Seizure receipt [reference-number=seizure_receipt]": "ex. 0000-00-0000"
        ,"Unique client identifier (UCI) number [reference-number=uci]": "ex. 0000-0000 or 00-0000-0000"
    }

    // INQUIRY - PERSONA/PEOPLE OPTIONS

    , statusInCanadaOptions: [
        { value: 'Canadian Citizen [statusInCanada=1]', label: messages['contact.i18n-statusInCanada-canadianCitizen']},
        { value: 'Foreign National [statusInCanada=2]', label: messages['contact.i18n-statusInCanada-foreignNational'] },
        { value: 'Permanent Resident [statusInCanada=3]', label: messages['contact.i18n-statusInCanada-permanentResident'] },
        { value: 'Person Registered Under The Indian Act [statusInCanada=4]', label: messages['contact.i18n-statusInCanada-personRegisteredUnderTheIndianAct'] },
        { value: 'Study Permit Holder [statusInCanada=5]' , label: messages['contact.i18n-statusInCanada-studyPermitHolder'] },
        { value: 'Work Permit Holder [statusInCanada=6]' , label: messages['contact.i18n-statusInCanada-workPermitHolder'] },
      ]
      , topicOptions: [
        { value: 'Currency [topic=currency]' , label: messages['contact.i18n-topic-currency'] },    
        { value: 'Delayed Shipment [topic=delayedShipment]' , label: messages['contact.i18n-topic-delayedShipment'] },
        { value: 'Exporting [topic=exporting]' , label: messages['contact.i18n-topic-exporting'] },
        { value: 'Importing [topic=importing]' , label: messages['contact.i18n-topic-importing'] },
        { value: 'Refunds and Appeals [topic=refundsAndAppeals]' , label: messages['contact.i18n-topic-refundsAndAppeals'] },
        { value: 'Travel Programs [topic=travelPrograms]' , label: messages['contact.i18n-topic-travel-TravelPrograms'] },
      ]
      , delayedShipmentOptions: [
        { value: 'arriving By Canada Post [subTopic=arrivingByCanadaPost]', label: messages['contact.i18n-topic-delayedShipment-arrivingByCanadaPost'] },
        { value: 'Arriving by courier [subTopic=arrivingByCourier]', label: messages['contact.i18n-topic-delayedShipment-arrivingByCourier'] },
        { value: 'Arriving by truck/freight [subTopic=arrivingByTruck-Freight]', label: messages['contact.i18n-topic-delayedShipment-arrivingByTruck-Freight'] },    
      ]
      , importingOptions: [
        { value: 'alcoholTobaccoCannabis [subTopic=alcohol_Tobacco_Cannabis]', label: messages['contact.i18n-topic-exporting-alcohol_Tobacco_Cannabis'] },
        { value: 'dutyAndTaxes [subTopic=dutyAndTaxes]', label: messages['contact.i18n-topic-importing-dutyAndTaxes'] },
        { value: 'firearmsAmmunitionsAndWeapons [subTopic=firearms_AmmunitionsAndWeapons]', label: messages['contact.i18n-topic-importing-firearms_AmmunitionsAndWeapons'] },
        { value: 'foodPlantAnimal [subTopic=food_Plant_Animal]', label: messages['contact.i18n-topic-importing-food_Plant_Animal'] },
        { value: 'medication [subTopic=medication]', label: messages['contact.i18n-topic-importing-medication'] },
        { value: 'selfClearanceReporting [subTopic=self-Clearance-Reporting]', label: messages['contact.i18n-topic-importing-self-Clearance-Reporting'] },
        { value: 'vehiclesAndBoats [subTopic=vehiclesAndBoats]', label: messages['contact.i18n-topic-importing-vehiclesAndBoats'] },
      ]
      , exportingOptions: [

        { value: 'alcoholTobaccoCannabis [subTopic=alcohol_Tobacco_Cannabis]', label: messages['contact.i18n-topic-exporting-alcohol_Tobacco_Cannabis'] },
        { value: 'firearmsAmmunitionsAndWeapons [subTopic=firearms_AmmunitionsAndWeapons]', label: messages['contact.i18n-topic-importing-firearms_AmmunitionsAndWeapons'] },
        { value: 'foodPlantAnimal [subTopic=food_Plant_Animal]', label: messages['contact.i18n-topic-importing-food_Plant_Animal'] },
        { value: 'medication [subTopic=medication]', label: messages['contact.i18n-topic-importing-medication'] },
        { value: 'vehiclesAndBoats [subTopic=vehiclesAndBoats]', label: messages['contact.i18n-topic-importing-vehiclesAndBoats'] },
      ]
      , travelProgramsOptions: [
        { value: 'movingToCanada [subTopic=movingToCanada]', label: messages['contact.i18n-topic-travel-movingToCanada'] },
        { value: 'seasonalResidence [subTopic=seasonalResidence]', label: messages['contact.i18n-topic-travel-seasonalResidence'] },
        { value: 'exemptions [subTopic=exemptions]', label: messages['contact.i18n-topic-travel-exemptions'] },
        { value: 'children [subTopic=children]', label: messages['contact.i18n-topic-travel-children'] },
        { value: 'inTransit [subTopic=in-Transit]', label: messages['contact.i18n-topic-travel-in-Transit'] },
        { value: 'nexus [subTopic=nexus]', label: messages['contact.i18n-topic-travel-nexus'] },
        { value: 'canpass [subTopic=canpass]', label: messages['contact.i18n-topic-travel-canpass'] },
      ]
})

