import React, {useContext} from 'react';
import { useFormikContext } from "formik";
import { IntlContext} from 'react-intl';
import { TextFieldWithLabel } from './FormFields';

export default function PreviouslyReportedComplaint(props) {
    const { values } = useFormikContext();
    const { messages } = useContext(IntlContext);

  return (
    <>
      {values["previousComplaint"][0] === "yes" &&
        <TextFieldWithLabel id="previousComplaintReference" name="previousComplaintReference" maxLength="250" className="form-control full-width" wrapperStyle={{}} required={true} label={messages["if-so-provide-the-cbsa"]} />
      // : 
      //   <TextFieldWithLabel id="previousComplaintReference" name="previousComplaintReference" maxLength="250" className="form-control full-width" wrapperStyle={{}} required={false} label={messages["if-so-provide-the-cbsa"]} />
      }
    </>
  )
}
