import React from "react";
import {FormattedMessage, FormattedDate} from 'react-intl';

function DateModified(props) {
	
	return (
		<>
			<div style={{width:"100%"}}>
				<span style={{float:'left'}}>
					<dl id="wb-dtmd">
						<dt><FormattedMessage id="wet.dateModified"/>&nbsp;</dt>
						<dd>
							<time property="dateModified">
								<FormattedDate value={props.dateModified === undefined ? Date.now() : props.dateModified} year="numeric" month="long" day="2-digit" />
							</time>
						</dd>
					</dl>
				</span>
				<span style={{float:'right', marginTop:40}} id="versionNumber">V {props.versionNumber}</span>
			</div>
		</>
	);
}

export default DateModified;