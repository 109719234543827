import React, { useContext } from 'react';
import { IntlContext} from 'react-intl';
import { useFormikContext, FieldArray } from "formik";
import { TextFieldWithLabel , TextAreaFieldWithLabel } from './FormFields';

export default function EmployeeDetailsDynamicRow() {
    const { values } = useFormikContext();
	const { messages } = useContext(IntlContext);

    return (
		<>
			<section className="panel panel-default error-parent">
				<header className="panel-heading bold">
					{messages['employees']}
				</header>
				<div className="panel-body">
					<p>{messages['details-employee-description']}</p>
					<FieldArray name="employeeDetails">
						{({insert, remove, push, pop}) => (
						<>
							{/* ENTRIES */}
							{values["employeeDetails"].map((employeeDetails, index) => { 
								const prefix = `employeeDetails[${index}].`;
								const employeeFirstName = prefix + "employeeFirstName"; 
								const employeeLastName = prefix + "employeeLastName"; 
								const employeeBadgeNumber = prefix + "employeeBadgeNumber"; 
								const employeeEmail = prefix + "employeeEmail"; 
								const employeeDescription = prefix + "employeeDescription"; 

								return (
									<section className="panel panel-default" key={`employee-${index}`}>
										<header className="panel-heading">
											{messages['employee']} {index + 1}
											<button type="button" className="glyphicon glyphicon-remove close-button" title={messages['button.delete']} onClick={() => remove(index)}/>
										</header>
										<div className="panel-body">
											<div className="row">
												{/* TODO: check the real field max lengths */}
												{/* TODO: we could probably fine-tune the xs, sm, medium sizes more */}
												<TextFieldWithLabel id={employeeFirstName} name={employeeFirstName} wrapperClassName="form-group col-md-3 col-sm-6" wrapperStyle={{}} maxLength="40" label={messages["first-name"]} />
												<TextFieldWithLabel id={employeeLastName} name={employeeLastName} wrapperClassName="form-group col-md-3 col-sm-6" wrapperStyle={{}} maxLength="40" label={messages["last-name"]} />
												<TextFieldWithLabel id={employeeBadgeNumber} name={employeeBadgeNumber} wrapperClassName="form-group col-md-2 col-sm-6" wrapperStyle={{}} maxLength="10" label={messages["badge"]} />
												<TextFieldWithLabel id={employeeEmail} name={employeeEmail} wrapperClassName="form-group col-md-4 col-sm-6" wrapperStyle={{}} maxLength="50" label={messages["email"]} />
											</div>
											<div className="row">
												<TextAreaFieldWithLabel id={employeeDescription} name={employeeDescription} wrapperClassName="form-group col-md-12" numOfRows={2}  maxLength="2500" label={messages["description"]} />
											</div>
										</div>
									</section>
								) 
							})}
							<button type="button" id="addEmployeeItem" className="add-button"
								title={messages['button.add']}
								onClick={() => push({ employeeFirstName: "" , employeeLastName: "" , employeeBadgeNumber: "" , employeeEmail: "" , employeeDescription: "" })}
							>
								<strong> + </strong>
							</button>
						</>
						)}
					</FieldArray>
				</div>
			</section>
		</>
    )
}