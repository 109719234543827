import React, { useContext } from 'react';
import { IntlContext } from 'react-intl';
import { useFormikContext, FieldArray } from "formik";
import { TextFieldWithLabel } from './FormFields';

export default function WitnessDetailsDynamicRow() {
    const { values } = useFormikContext();
	const { messages } = useContext(IntlContext);

    return (
		<>
			<section className="panel panel-default error-parent">
				<header className="panel-heading bold">
					{messages['witnesses']}
				</header>
				<div className="panel-body">
					<p>{messages['contact-witnesses-information']}
					</p>
					<FieldArray name="witnessDetails">
						{({insert, remove, push, pop}) => (
						<>
							{values["witnessDetails"].map((witnessDetails, index) => { 
								const prefix = `witnessDetails[${index}].`;
								const witnessFirstName = prefix + "witnessFirstName";
								const witnessLastName = prefix + "witnessLastName";
								const witnessTelephone = prefix + "witnessTelephone";
								const witnessEmail = prefix + "witnessEmail";
								return (
									<section className="panel panel-default" key={`witness-${index}`}>
										<header className="panel-heading">
											{messages['witness']} {index + 1}
											<button type="button" className="glyphicon glyphicon-remove close-button" title={messages['button.delete']} onClick={() => remove(index)}/>
										</header>
										<div className="panel-body">
											<div className="row">
												{/* TODO: check the real field max lengths */}
												{/* TODO: we could probably fine-tune the xs, sm, medium sizes more */}
												<TextFieldWithLabel id={witnessFirstName} name={witnessFirstName} wrapperClassName="form-group col-md-3 col-sm-6" wrapperStyle={{}} maxLength="40" label={messages["first-name"]} />
												<TextFieldWithLabel id={witnessLastName} name={witnessLastName} wrapperClassName="form-group col-md-3 col-sm-6" wrapperStyle={{}} maxLength="40" label={messages["last-name"]} />
												<TextFieldWithLabel id={witnessTelephone} name={witnessTelephone} wrapperClassName="form-group col-md-3 col-sm-6" wrapperStyle={{}} maxLength="10" label={messages["telephone"]} />
												<TextFieldWithLabel id={witnessEmail} name={witnessEmail} wrapperClassName="form-group col-md-3 col-sm-6" wrapperStyle={{}} maxLength="50" label={messages["email"]} />
											</div>
										</div>
									</section>
								)
							})}
							<button type="button" id="addWitnessItem" className="add-button"
								title={messages['button.add']}
								onClick={() => push({ witnessFirstName: "" , witnessLastName: "" , witnessTelephone: "" , witnessEmail: "" })}
							>
								<strong> + </strong>
							</button>
						</>
						)}
					</FieldArray>
				</div>
			</section>
		</>
    )
}
