// Import of pages in the app
import CSform from './CSform/index';
import CIPSForm from './CIPSForm/index';
import CIPSSubmissionForm from './CIPSSubmissionForm';
import Results from './Results/index';

/**
 * All of the scenes (pages) in the app (or any app you want to make a <Link/> to)
 * for use by react-navigation/core > SwitchRouter 
 */
export const scenes = {
	CSform
	, CIPSForm
	, CIPSSubmissionForm
	, Results
};