import React, { useState, useEffect, useContext } from 'react';
import {FormattedMessage, IntlContext} from 'react-intl';
import { useFormikContext } from "formik";
import MagnifiquePopup from './MagnifiquePopup';
import { CheckboxWithLabel } from './FormFields';
import { currentLang } from '../utils/miscUtils';

export default function TypeOfComplaint(props) {
    const { values } = useFormikContext();
	const { messages } = useContext(IntlContext);
	
	const [cvborPopup, setCvborPopup] = useState(false);
    const [cvborTrigger, setCvborTrigger] = useState(false);
	const [goahead, setGoahead] = useState(false);
    const [backdown, setBackdown] = useState(false);

    const CONDUCT_VALUE = "Conduct [type-of-complaint=conduct]";
    const SERVICE_VALUE = "Level of Service [type-of-complaint=level_of_service]";
    const CVBOR_VALUE = "Canadian Victims Bill of Rights [type-of-complaint=cvbr]";

    const CVBOR_URL = `https://laws-lois.justice.gc.ca/${currentLang() === 'en' ? 'eng/acts' : 'fra/lois'}/C-23.7/page-1.html`
	
	useEffect(() => {
        if (cvborTrigger === true) {
            if (values.typeOfComplaint.includes(CVBOR_VALUE)) {
                setCvborPopup(true);
            }
            setCvborTrigger(false);
        }
		if (goahead === true) {
            values.cvbor = 'yes';
            setGoahead(false);
		}
        if (backdown === true) {
            values.typeOfComplaint.pop(CVBOR_VALUE);
            setBackdown(false);
        }
        if (!values.typeOfComplaint.includes(CVBOR_VALUE)) {
            values.cvbor = 'no';
        }
	}, [goahead, backdown, cvborTrigger, values.typeOfComplaint]);

    return(
        <>
            <div className="row error-parent">
                <div className="form-group col-md-3 col-sm-5" style={{display: 'flex', justifyContent: 'center'}}>
                    <label className="control-label required">
                        <span>
                            <FormattedMessage id="type-of-complaint" />
                        </span>
                        <span> </span>
                        <strong className="required">
                            <FormattedMessage id="required" />
                        </strong>
                    </label>
                </div>
                <div className="col-md-9 col-sm-7" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="form-group">
                        <CheckboxWithLabel id="typeOfComplaint" name="typeOfComplaint" required={true}  
                            // TODO: Refactor with constants (cvbor on-click will need changing)
                            options={[
                                {value: CONDUCT_VALUE, required:false, label:messages["conduct"]}
                                , {value: SERVICE_VALUE, required:false, label:messages["level-of-service"]}
                                , {value: CVBOR_VALUE, required:false, label:messages["canadian-victims-bill-of-rights"], onClick:setCvborTrigger}
                            ]} 
                        />
                    </div>
                </div>
            </div>

            <MagnifiquePopup 
                id="cvborModal" 
                trigger={cvborPopup} 
                setTrigger={setCvborPopup} 
                setGo={setGoahead} 
                setBack={setBackdown}
                header={<FormattedMessage id="canadian-victims-bill-of-rights" />} 
                body={<>
                    <p><FormattedMessage id="closely-details-canadian" /><br /><FormattedMessage id="following-conditions-complaints" />
                    </p>
                    <br />
                    <ol>
                        <li> <FormattedMessage id="the-cbsa-must-be-currently" /> </li>
                        <li> <FormattedMessage id="the-definition-of-a-victim" /> </li>
                        <li> <FormattedMessage id="you-must-be-present-in" /> </li>
                        <li> <FormattedMessage id="you-believe-that-the-cbsa" /> </li>
                    </ol>
                    <br />
                    <p>
                        <FormattedMessage id="additional-conditions-information" 
                            values={{
                                a: (chunks) => <a href={CVBOR_URL}>{chunks}</a>, 
                                strong: (chunks) => <strong>{chunks}</strong>
                            }} 
                        />
                    </p>
                    <strong className="required" style={{color: 'rgb(177, 0, 0)'}}>
                        <FormattedMessage id="required" />
                    </strong>
                    <br />
                </>} 
                cancelButton={<FormattedMessage id="button.no" />} 
                continueButton={<FormattedMessage id="button.yes" />} 
            />
        </>
    );
}