import React, { useEffect, useState, useContext } from 'react';
import { Link } from '@react-navigation/web';
import {FormattedMessage, IntlContext} from 'react-intl';
import ComplaintForm from './Complaint/index.js';
import ComplimentForm from './Compliment/index.js';
import { currentLongLang, getOtherLangHref, currentLang, currentLangParam } from '../../utils/miscUtils.js';

function CIPSSubmissionForm({navigation}) {
	const { messages } = useContext(IntlContext);
	const [feedbackTypeDropdown, setFeedbackTypeDropdown] = useState("")

	const handleFeedbackTypeDropdownChange = (event) => {
		setFeedbackTypeDropdown(event.target.value);
	}

	useEffect(() => {
		document.title = messages['submit-your-feedback'];
		setFeedbackTypeDropdown(navigation.state?.params?.type)
	}, [navigation.state?.params]);

	return (
		<div>
			<details>
				<summary>
					<FormattedMessage id="privacy-notice" />
				</summary>
				<p className="mrgn-tp-md">
					<FormattedMessage id="investigating-international-investigations" 
						values={{
							span: (chunks) => <span className="text-nowrap">{chunks}</span>, 
							cite: (chunks) => <cite>{chunks}</cite>
						}} 
					/>
				</p>
				<p className="mrgn-bttm-0"><FormattedMessage id="individuals-corrections-correspondence" values={{b: (chunks) => <b>{chunks}</b>, strong: (chunks) => <strong>{chunks}</strong>, cite: (chunks) => <cite>{chunks}</cite>, a1: (chunks) => <a href={"https://www.cbsa-asfc.gc.ca/agency-agence/reports-rapports/pia-efvp/atip-aiprp/infosource-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html#a2"}>{chunks}</a>, a2: (chunks) => <a href={"https://www.infosource.gc.ca"}>{chunks}</a>}} />
				</p>
			</details>
			<section className="alert alert-info mrgn-tp-md">
				<h2 className="h3">
					<FormattedMessage id="note" />
				</h2>
				<p><FormattedMessage id="maintaining-responsible-authorization" />
				</p>
				<p lang="fr"><FormattedMessage id="formulaire-communiquer-retroaction" values={{a: (chunks) => <a className="alert-link" href={getOtherLangHref()}>{currentLang() === 'en' }{chunks}</a>}} />
				</p>
			</section>
			<div className="row mrgn-bttm-md">
				<div className="form-group col-md-4">
					<label htmlFor="feedbackType" className="control-label required">
						<FormattedMessage id="CIPS.i18n-feedbackType" /><strong className="required"> <FormattedMessage id="common.required.inBrackets"/></strong>
					</label>
					<div>
						<select id="feedbackType" value={feedbackTypeDropdown} onChange={handleFeedbackTypeDropdownChange} className="form-control full-width">
							<option value="0">{messages['CIPS.i18n-feedbackType']}</option>
							<option value="compliment">{messages['CIPS.i18n-feedbackType-compliment']}</option>
							<option value="complaint">{messages['CIPS.i18n-feedbackType-complaint']}</option>
						</select>
					</div>
				</div>					
			</div>
			{/* COMPLAINT */} 
			{feedbackTypeDropdown === 'complaint' && <ComplaintForm navigation={navigation}/>}
			
			{/* COMPLIMENT */} 
			{feedbackTypeDropdown === 'compliment' && <ComplimentForm navigation={navigation} />}
		</div>
	);
}

CIPSSubmissionForm.path = "Cipssubmissionform";
CIPSSubmissionForm.navigationOptions = {
	pageTitle: <FormattedMessage id="compliments-comments-and-complaints-title" />
	, breadcrumb: [
		<a href={`https://www.cbsa-asfc.gc.ca/menu-${currentLongLang()}.html`}><FormattedMessage id="breadcrumbs.cbsaHome"/></a>,
		<Link routeName="CIPSForm" params={currentLangParam()}><FormattedMessage id="feedback" /></Link>
	]
	, breadcrumbTitle: <FormattedMessage id="submit-your-feedback" />
};

export default CIPSSubmissionForm;