// ! Remember, any changes here need to be reflected in the inquiry.json JSON schema

export const UnitEnum = Object.freeze({
	BIS: 'BIS', 
	TCCU: 'TCCU',
	CCSSH: 'CCSSH' 
});

export const FieldEnum = Object.freeze({
	businessName: {
		rowId: 'fieldRow-businessName',
		fieldId: 'businessName',
	},
	businessNumber: {
		rowId: 'fieldRow-businessNumber',
		fieldId: 'businessNumber',
	},
	rmNumber: {
		rowId: 'fieldRow-rmNumber',
		fieldId: 'rmNumber',
	},
	bondNumber: {
		rowId: 'fieldRow-bondNumber',
		fieldId: 'bondNumber',
	},
	carrierCode: {
		rowId: 'fieldRow-carrierCode',
		fieldId: 'carrierCode',
	},

	accountSecurityNumber: {
		rowId: 'fieldRow-accountSecurityNumber',
		fieldId: 'accountSecurityNumber',
	},

	subOfficeLocation: {
		rowId: 'fieldRow-subOfficeLocation',
		fieldId: 'subOfficeLocation',
	},

	conveyanceReferenceNumber: {
		rowId: 'fieldRow-conveyanceReferenceNumber',
		fieldId: 'conveyanceReferenceNumber',
	},

	cargoControlNumber: {
		rowId: 'fieldRow-cargoControlNumber',
		fieldId: 'cargoControlNumber',
	},

	modeOfTransport: {
		rowId: 'fieldRow-modeOfTransport',
		fieldId: 'modeOfTransport',
	},

	transactionNumber: {
		rowId: 'fieldRow-transactionNumber',
		fieldId: 'transactionNumber',
	},

	dateOfTransaction: {
		rowId: 'fieldRow-dateOfTransaction',
		fieldId: 'dateOfTransaction',
	},

	transactionAmount: {
		rowId: 'fieldRow-transactionAmount',
		fieldId: 'transactionAmount',
	},

	statementOfAccountDate: {
		rowId: 'fieldRow-statementOfAccountDate',
		fieldId: 'statementOfAccountDate',
	},

	statementOfAccountAmount: {
		rowId: 'fieldRow-statementOfAccountAmount',
		fieldId: 'statementOfAccountAmount',
	},

	dailyNoticeDate: {
		rowId: 'fieldRow-dailyNoticeDate',
		fieldId: 'dailyNoticeDate',
	},

	dailyNoticeAmount: {
		rowId: 'fieldRow-dailyNoticeAmount',
		fieldId: 'dailyNoticeAmount',
	},

	previousTicketNumber: {
		rowId: 'fieldRow-previousTicketNumber',
		fieldId: 'previousTicketNumber',
	},

	chequeNumber: {
		rowId: 'fieldRow-chequeNumber',
		fieldId: 'chequeNumber',
	},

	dateOnCheque: {
		rowId: 'fieldRow-dateOnCheque',
		fieldId: 'dateOnCheque',
	},

	chequeAmount: {
		rowId: 'fieldRow-chequeAmount',
		fieldId: 'chequeAmount',
	},
	paymentAmount: {
		rowId: 'fieldRow-paymentAmount',
		fieldId: 'paymentAmount',
	},
	paymentDate: {
		rowId: 'fieldRow-paymentDate',
		fieldId: 'paymentDate',
	},
});

/* 
	NOTE: there are some entries in here which are not used and as such did not get parsed with a real value
	e.g. CAT-01033. These are not used in the original app but are present in the commented out code which I
	assume this object was generated from. Leaving them in here just in case. 
 */
export const SubCategoriesToFields = {
	'': {
		optionalFields: [],
		requiredFields: [],
	},
	'Carrier/Freight Forwarder program enrolment [subCategory=CAT-01013]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Customs brokers licensing [subCategory=CAT-01014]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'How do I enrol in the Partners in Protection program [subCategory=CAT-01172]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Help with exporter program enrolment [subCategory=CAT-01015]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Trusted traders [subCategory=CAT-01019]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Warehouse licensing [subCategory=CAT-01020]': {
		unit: UnitEnum.BIS,
		optionalFields: [
			FieldEnum.businessNumber,
		],
		requiredFields: [FieldEnum.businessName],
	},
	'Help with an ACI EDI transmission [subCategory=CAT-01031]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.conveyanceReferenceNumber,
			FieldEnum.cargoControlNumber,
			FieldEnum.modeOfTransport,
		],
		requiredFields: [FieldEnum.businessName],
	},
	'Help with a B3 EDI transmission [subCategory=CAT-01032]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.dateOfTransaction,
		],
		requiredFields: [FieldEnum.businessName, FieldEnum.transactionNumber],
	},
	'CAT-01033': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.dateOfTransaction,
		],
		requiredFields: [FieldEnum.businessName, FieldEnum.transactionNumber],
	},
	'Help with a G7 Export EDI transmission [subCategory=CAT-01034]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Help with a Release or IID EDI transmission [subCategory=CAT-01035]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.dateOfTransaction,
		],
		requiredFields: [FieldEnum.businessName, FieldEnum.transactionNumber],
	},
	'Help with an RNS EDI transmission [subCategory=CAT-01036]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.dateOfTransaction,
		],
		requiredFields: [FieldEnum.businessName, FieldEnum.transactionNumber],
	},
	'CAT-01037': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.dateOfTransaction,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.subOfficeLocation,
			FieldEnum.conveyanceReferenceNumber,
			FieldEnum.cargoControlNumber,
		],
	},
	'CAT-01038': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.accountSecurityNumber,
		],
	},
	'CAT-01039': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'EDI Enrolment:  ACI & Notices [subCategory=CAT-01125]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.carrierCode,
			FieldEnum.accountSecurityNumber,
			FieldEnum.subOfficeLocation,
		],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01126': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01127': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Enrol in EDI participant distribution lists [subCategory=CAT-01128]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.carrierCode,
			FieldEnum.accountSecurityNumber,
			FieldEnum.subOfficeLocation,
		],
		requiredFields: [FieldEnum.businessName],
	},
	'EDI Enrolment: G7 Export [subCategory=CAT-01129]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'EDI Enrolment: IID (Single Window) [subCategory=CAT-01130]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.accountSecurityNumber,
		],
	},
	'EDI Enrolment: Release [subCategory=CAT-01131]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.accountSecurityNumber,
		],
	},
	'EDI Enrolment: Release Notification System [subCategory=CAT-01132]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.accountSecurityNumber,
		],
	},
	'CAT-01133': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.accountSecurityNumber,
		],
	},
	'CAT-01134': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.carrierCode,
		],
		requiredFields: [FieldEnum.businessName, FieldEnum.subOfficeLocation],
	},
	'Request ECCRD - ACI & Notices [subCategory=CAT-01135]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01136': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01137': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Request ECCRD - G7 Export [subCategory=CAT-01138]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Request ECCRD - Release / SWI [subCategory=CAT-01139]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Request ECCRD - RNS [subCategory=CAT-01140]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01141': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'General Guidelines for exporting goods [subCategory=CAT-01040]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.modeOfTransport],
		requiredFields: [],
	},
	'Trade incentives [subCategory=CAT-01074]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'A transaction does not belong to me [subCategory=CAT-01043]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.transactionNumber,
		],
	},
	'CARM Client Portal: Help with my financial account [subCategory=CAT-01044]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Help with reading my financial portal account [subCategory=CAT-01045]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I received a cheque and do not know what it is for [subCategory=CAT-01046]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.chequeNumber,
			FieldEnum.dateOnCheque,
			FieldEnum.chequeAmount,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Updating my address and contact information [subCategory=CAT-01047]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	"I haven't received my cheque yet [subCategory=CAT-01048]": {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.chequeNumber,
			FieldEnum.dateOnCheque,
			FieldEnum.chequeAmount,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I would like to request a refund [subCategory=CAT-01049]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.accountSecurityNumber,
			FieldEnum.transactionNumber,
			FieldEnum.dateOfTransaction,
			FieldEnum.transactionAmount,
			FieldEnum.statementOfAccountDate,
			FieldEnum.statementOfAccountAmount,
			FieldEnum.dailyNoticeDate,
			FieldEnum.dailyNoticeAmount,
			FieldEnum.previousTicketNumber,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Daily Notice/Statement - I would like to receive daily notices and statement of account [subCategory=CAT-01050]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.accountSecurityNumber,
		],
	},
	'Daily Notice/Statement - I did not receive my daily notice or statement of account (SOA) [subCategory=CAT-01051]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.accountSecurityNumber,
			FieldEnum.statementOfAccountDate,
			FieldEnum.statementOfAccountAmount,
			FieldEnum.dailyNoticeDate,
			FieldEnum.dailyNoticeAmount,
			FieldEnum.previousTicketNumber,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Daily Notice/Statement - I do not understand my daily notice or statement of account (SOA) [subCategory=CAT-01052]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.accountSecurityNumber,
			FieldEnum.statementOfAccountDate,
			FieldEnum.statementOfAccountAmount,
			FieldEnum.dailyNoticeDate,
			FieldEnum.dailyNoticeAmount,
			FieldEnum.previousTicketNumber,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'A transaction is missing [subCategory=CAT-01057]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.dateOfTransaction,
			FieldEnum.transactionAmount,
			FieldEnum.statementOfAccountDate,
			FieldEnum.statementOfAccountAmount,
			FieldEnum.dailyNoticeDate,
			FieldEnum.dailyNoticeAmount,
			FieldEnum.previousTicketNumber,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.transactionNumber,
		],
	},
	'I need help with a notice of arrears [subCategory=CAT-01058]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I cannot import because my account is frozen [subCategory=CAT-01059]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I would like to apply a credit from one of my accounts to another account [subCategory=CAT-01060]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	"I don't understand an offset on my account [subCategory=CAT-01061]": {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'My opening balance is incorrect [subCategory=CAT-01142]': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.accountSecurityNumber,
			FieldEnum.statementOfAccountDate,
			FieldEnum.statementOfAccountAmount,
			FieldEnum.dailyNoticeDate,
			FieldEnum.dailyNoticeAmount,
			FieldEnum.previousTicketNumber,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I paid CBSA instead of CRA [subCategory=CAT-01065]': {
		//Payment - CBSA instead of CRA
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.paymentAmount, FieldEnum.paymentDate],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I want to confirm receipt of a payment [subCategory=CAT-01066]': {
		//Payment - Confirm receipt
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.paymentAmount, FieldEnum.paymentDate],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I paid CRA instead of CBSA [subCategory=CAT-01067]': {
		//Payment - CRA instead of CBSA
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.paymentAmount, FieldEnum.paymentDate],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I would like to set up EDI payment [subCategory=CAT-01143]': {
		//Payment - EDI 820 set up
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I paid the wrong amount / duplicate payment [subCategory=CAT-01144]': {
		//Payment - Incorrect payment
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.paymentAmount, FieldEnum.paymentDate],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	"I made a payment and I don't see that it was processed  [subCategory=CAT-01145]": {
		//Payment - Missing a payment
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.paymentAmount, FieldEnum.paymentDate],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I made a payment made by cheque [subCategory=CAT-01146]': {
		//Payment - Payment made by cheque
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I need to apply a payment so that I can submit a B2 request [subCategory=CAT-01147]': {
		//Payment - Pre-pay B2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.transactionAmount],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'How do I make a payment [subCategory=CAT-01148]': {
		//Payment - How to make a
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'How do I enrol in/change pre-authorized debit payments (PAD) [subCategory=CAT-01149]': {
		//Payment - PAD
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Request ECCRD - Daily Notice /Statement of Account [subCategory=CAT-01153]': {
		//Payment - Request Daily Notice/SOA ECCRD
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'I would like to enrol in the Release prior to payment program [subCategory=CAT-01154]': {
		//RPP - enrol in program
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'How much security do I need to post for the RPP? [subCategory=CAT-01155]': {
		//RPP - How much
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'How do I post cash as security? [subCategory=CAT-01156]': {
		//RPP - Cash as security
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'What is release prior to payment security, how do I enrol? [subCategory=CAT-01157]': {
		//RPP - General
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Help with understanding a transaction [subCategory=CAT-01158]': {
		//Transaction info request
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.dateOfTransaction,
			FieldEnum.transactionAmount,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.transactionNumber,
		],
	},
	'Other - financial account activity [subCategory=CAT-01063]': {
		//Other - Financial account activity
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'First Time Importer [subCategory=CAT-01068]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'General importations [subCategory=CAT-01069]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'Help with Importer Program enrolment [subCategory=CAT-01189]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Rulings [subCategory=CAT-01070]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01071': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Temporary Imports/Remissions [subCategory=CAT-01072]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Trade Agreements [subCategory=CAT-01073]': {
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.businessNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01076': {
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CAT-01077': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName, FieldEnum.businessNumber],
	},
	'CAT-01078': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01079': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01080': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01081': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01082': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01083': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CAT-01084': {
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
		requiredFields: [],
	},
	'CAT-01085': {
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Canadian Export Reporting System Portal - Activate my business account [subCategory=CAT-01086]': {
		unit: UnitEnum.TCCU,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Canadian Export Reporting System Portal - Managing my export declarations [subCategory=CAT-01160]': {
		unit: UnitEnum.TCCU,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Canadian Export Reporting System Portal - Other [subCategory=CAT-01161]': {
		unit: UnitEnum.TCCU,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Canadian Export Reporting System Portal - Setting up my user account and preferences [subCategory=CAT-01162]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'eManifest Portal - Activate my business account [subCategory=CAT-01163]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName, FieldEnum.carrierCode],
	},
	'eManifest Portal - Manage ACI declarations [subCategory=CAT-01164]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.conveyanceReferenceNumber,
			FieldEnum.cargoControlNumber,
			FieldEnum.modeOfTransport,
		],
		requiredFields: [FieldEnum.businessName, FieldEnum.carrierCode],
	},
	'eManifest Portal - Other [subCategory=CAT-01165]': {
		unit: UnitEnum.TCCU,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'eManifest Portal - Setting up my user account and preferences [subCategory=CAT-01166]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.carrierCode,
			FieldEnum.subOfficeLocation,
			FieldEnum.conveyanceReferenceNumber,
			FieldEnum.cargoControlNumber,
			FieldEnum.modeOfTransport,
		],
		requiredFields: [FieldEnum.businessName],
	},
	'Partners in Protection (PIP) portal  [subCategory=CAT-01167]': {
		unit: UnitEnum.TCCU,
		optionalFields: [
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.carrierCode,
			FieldEnum.subOfficeLocation,
		],
		requiredFields: [FieldEnum.businessName],
	},
	'General guidelines for bonded warehouses trade incentives program [subCategory=CAT-01087]': {
		//Storing commercial goods - BW Guidelines
		unit: UnitEnum.BIS,
		optionalFields: [],
		requiredFields: [FieldEnum.businessName],
	},
	'General guidelines for sufferance warehouses [subCategory=CAT-01088]': {
		//Storing commercial goods - SW Guidelines
		unit: UnitEnum.BIS,
		optionalFields: [],
		requiredFields: [FieldEnum.businessName],
	},
	'Guidelines for Transporting commercial goods for exportation abroad [subCategory=CAT-01089]': {
		//Transporting/Logistics - Transporter Guidelines - Export
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'Guidelines for Transporting commercial goods for importation to Canada [subCategory=CAT-01090]': {
		//Transporting/Logistics - Transporter Guidelines - Import
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'Guidelines for Transporting commercial goods through Canada or the US [subCategory=CAT-01091]': {
		//Transporting/Logistics - Transporter Guidelines - InTrans
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'Transporter obligations [subCategory=CAT-01093]': {
		//Transporting/Logistics - Transporter obligations
		unit: UnitEnum.BIS,
		optionalFields: [FieldEnum.modeOfTransport],
		requiredFields: [FieldEnum.businessName],
	},
	'I need help with a bond claim [subCategory=CAT-01265]': {
		//CFS R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.bondNumber,
		],
	},
	'Help with finding information on the web site [subCategory=CAT-01169]': {
		//CCSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'I wish to sign up for a CARM webinar [subCategory=CAT-01171]': {
		//CCSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'I wish to sign up for CARM bulletins [subCategory=CAT-01170]': {
		//CCSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'EDI Enrollment - CAD [subCategory=CAT-01250]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'EDI Enrollment - Statement of Account (SOA) and Daily Notices (DN) [subCategory=CAT-01251]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Help with a CAD EDI transmission [subCategory=CAT-01247]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
			FieldEnum.transactionNumber,
			FieldEnum.dateOfTransaction,
		],
	},
	'Help with an API service [subCategory=CAT-01249]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [
			FieldEnum.bondNumber,
			FieldEnum.transactionNumber,
			FieldEnum.dateOfTransaction,
		],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Help with an EDI DN/SOA [subCategory=CAT-01248]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.accountSecurityNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Request ECCRD - CAD [subCategory=CAT-01252]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'Request ECCRD - DN/SOA [subCategory=CAT-01253]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Business profile [subCategory=CAT-01209]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - List of program accounts [subCategory=CAT-01211]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Program account profile [subCategory=CAT-01210]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'Activate my account [subCategory=CAT-01212]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Create a declaration [subCategory=CAT-01213]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Duties and taxes calculator [subCategory=CAT-01254]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - List of submitted declarations [subCategory=CAT-01214]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Manage my templates [subCategory=CAT-01215]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Request declaration report [subCategory=CAT-01218]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Statements of adjustment & appeals [subCategory=CAT-01216]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Summary of drawback activity [subCategory=CAT-01217]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - View list of requested declaration reports [subCategory=CAT-01219]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - CBSA invoices [subCategory=CAT-01222]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Collection letters - Notice of Arrears [subCategory=CAT-01223]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Collection letters - Notice of Lien [subCategory=CAT-01224]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Financial security [subCategory=CAT-01225]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.bondNumber],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Late payment interest simulation [subCategory=CAT-01226]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Statement of account [subCategory=CAT-01221]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Transaction history [subCategory=CAT-01220]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Logging on [subCategory=CAT-01227]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Navigating [subCategory=CAT-01228]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Using Filters [subCategory=CAT-01229]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Help with notifications [subCategory=CAT-01230]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Other [subCategory=CAT-01256]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Allocate credit as payment [subCategory=CAT-01231]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Online payment [subCategory=CAT-01234]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Payment arrangement [subCategory=CAT-01233]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Pre-authorized debit [subCategory=CAT-01232]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [],
		requiredFields: [
			FieldEnum.businessName,
			FieldEnum.businessNumber,
			FieldEnum.rmNumber,
		],
	},
	'CCP - Appeals [subCategory=CAT-01235]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Disenrollment and compliance [subCategory=CAT-01237]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Enforcement actions [subCategory=CAT-01236]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Rulings [subCategory=CAT-01255]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Account Deactivated/Locked [subCategory=CAT-01243]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Enroll in a CBSA program [subCategory=CAT-01241]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Manage my business relationships [subCategory=CAT-01240]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Manage my employees [subCategory=CAT-01239]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Personal profile [subCategory=CAT-01238]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Problems with BAM [subCategory=CAT-01244]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - View the API key [subCategory=CAT-01242]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
	'CCP - Document [subCategory=CAT-01245]': {
		//CCSSH R2
		unit: UnitEnum.CCSSH,
		optionalFields: [FieldEnum.businessNumber, FieldEnum.rmNumber],
		requiredFields: [FieldEnum.businessName],
	},
};