import React, { useEffect, useContext } from 'react';
import { FormattedMessage, IntlContext } from 'react-intl';
import { Link } from '@react-navigation/web';
import { currentLongLang, currentLangParam } from '../../utils/miscUtils';
import { FormTypes } from '../../constants';

import './results.css';

const cbsaHomeUrl = `https://www.cbsa-asfc.gc.ca/menu-${currentLongLang()}.html`

const formNavigate = {
    [FormTypes.INQUIRY]: 'CSform',
    [FormTypes.COMPLAINT]: 'CIPSForm',
    [FormTypes.COMPLIMENT]: 'CIPSForm',
};

const formTitle = {
    [FormTypes.INQUIRY]: <FormattedMessage id="contact-us" />,
    [FormTypes.COMPLAINT]: <FormattedMessage id="feedback" />,
    [FormTypes.COMPLIMENT]: <FormattedMessage id="feedback" />,
};

function Results({ navigation }) {
    const { preamble, results, form } = navigation.state.params;
	const { messages } = useContext(IntlContext);

    useEffect(() => {
        window.scrollTo(0, 0); // Workaround for screen rendering at the bottom of the page
        document.title = messages['results']
    }, [form, results, navigation]);
    
    const validLoad = results && typeof results  === 'object'; //If user refreshes screen or changes lang on results screen the results are lost so just re-direct them
    if (!validLoad) {
        if(form)
            navigation.navigate(formNavigate[form]);
        else window.location.href = cbsaHomeUrl; //trying to switch lang on results page causes big errors because none of the navigation stuff is kept, so just re-direct
    }

    return ( !validLoad ? <></> : 
        <>
            {preamble}

            <h3><FormattedMessage id="contact.i18n-results-submitted-info" /></h3>
            {Object.keys(results)?.map((key) => (
                results[key]?.data && (
                    <div key={key}>
                        <h4 className='results-header'>{results[key]?.label}</h4>
                        <div className="results-section" style={{ whiteSpace: 'pre-line' }}>
                            {results[key]?.data?.map((typeData, index) => (
                                <p key={index}>
                                    <span><strong>{typeData?.label}</strong>: </span>
                                    <span>{typeData?.data}</span>
                                </p>
                            ))}
                        </div>
                    </div>
                )
            ))}

            <br/><br/>
            <a href={cbsaHomeUrl}>
                <FormattedMessage id="return-home" />
            </a>
        </>
    );
}

Results.path = "Results";
Results.navigationOptions = ({ navigation }) => ({
    pageTitle: "",
    breadcrumb: [
        <a href={cbsaHomeUrl}><FormattedMessage id="breadcrumbs.cbsaHome" /></a>,
        navigation.state.params?.form && formNavigate[navigation.state.params.form] && (
            <Link params={currentLangParam()} routeName={formNavigate[navigation.state.params.form]}>
                {formTitle[navigation.state.params.form]}
            </Link>
        )
    ],
    breadcrumbTitle: <FormattedMessage id="results" />
});

export default Results;
